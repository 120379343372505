/* eslint-disable react/prop-types */
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { TableListProvider, TableMetadataType, useAuth } from '@think-zambia-foundation/context';
import {
  EnrollmentSubjectType,
  openUpdateEnrollmentSubjectDialog,
  setUpdateEnrollmentSubjectId,
} from '@think-zambia-foundation/katanga-context';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { UpdateEnrollmentSubjectDialog } from '../../components';
import { UpdateSubjectScoreProps } from './UpdateSubjectScore.types';

function Wrapped({ enrollmentSubjects, tableMetadata }: UpdateSubjectScoreProps) {
  if (!enrollmentSubjects) return <div />;
  return (
    <TableList
      data={enrollmentSubjects}
      disableSearch
      elevation={0}
      hidePagination
      tableMetadata={tableMetadata}
    />
  );
}

export function UpdateSubjectScore({ enrollmentSubjects }: UpdateSubjectScoreProps) {
  const [searchParams] = useSearchParams();
  const selectedStudentId = searchParams.get('selectedStudentId');

  const { hasPermissionToClaim } = useAuth();
  const dispatch = useDispatch();

  function onClickCell(enrollmentSubjectId: string) {
    if (hasPermissionToClaim('katangaScoreAdmin')) {
      dispatch(openUpdateEnrollmentSubjectDialog());
      dispatch(setUpdateEnrollmentSubjectId(enrollmentSubjectId));
    }
  }

  function calculateLetterGrade(score: number) {
    if (!score) return '-';
    if (score <= 59) return 'F';
    if (score <= 69) return 'D';
    if (score <= 79) return 'C';
    if (score <= 89) return 'B';
    return 'A';
  }

  const [tableMetadata] = useState<TableMetadataType>({
    tableKey: 'enrollmentSubjectId',
    onClickCell,
    cells: [
      {
        key: 'enrollmentSubjectId',
        header: 'Subject',
        value(data: EnrollmentSubjectType) { return data.subject.name; },
        variant: 'name',
      }, {
        key: 'enrollmentSubjectId',
        header: 'Score (%)',
        value(data: EnrollmentSubjectType) { return `${data.score || '-'}`; },
        variant: 'name',
      }, {
        key: 'score',
        header: 'Grade',
        value(data: EnrollmentSubjectType) { return `${calculateLetterGrade(data.score)}`; },
        variant: 'name',
      }],
  });

  if (!selectedStudentId) return <div />;
  if (!enrollmentSubjects || enrollmentSubjects.length < 1) return <div />;
  return (
    <CollapsibleCard
      title="Update Subject Score"
    >
      <TableListProvider>
        <Wrapped
          tableMetadata={tableMetadata}
          enrollmentSubjects={enrollmentSubjects}
        />
      </TableListProvider>
      <UpdateEnrollmentSubjectDialog />
    </CollapsibleCard>
  );
}
