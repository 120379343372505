import {
  Grid,
  Typography,
  TextField,
  Container,
  FormControl,
  MenuItem,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  useEffect, useState,
} from 'react';
import {
  closeCreateEnrollmentDialog,
  EnrollmentClassType,
  EnrollmentGradeType, KatangaSlice, SchoolType, setRegister, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { CreateEnrollmentDialogProps } from './CreateEnrollmentDialog.types';

const terms = ['ONE', 'TWO', 'THREE'];

function Wrapped({
  student, application,
}: CreateEnrollmentDialogProps) {
  const {
    createEnrollment,
    createEnrollmentData,
    creatingEnrollment,
    getSchools,
    fetchingSchools,
    errorFetchingSchools,
    fetchSchoolsData,
  } = useKatanga();

  const dispatch = useDispatch();

  const createEnrollmentDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.createEnrollmentDialogOpen,
  );
  const term = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.term,
  );
  const year = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.year,
  );
  const register = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.register,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  const navigate = useNavigate();

  const currentYear = (new Date()).getFullYear();
  const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

  useEffect(() => {
    setDialogOpen(createEnrollmentDialogOpen);
    // eslint-disable-next-line
  }, [createEnrollmentDialogOpen]);

  const [schools, setSchools] = useState<SchoolType[]>();
  const [enrollmentGrades, setEnrollmentGrades] = useState<EnrollmentGradeType[]>();
  const [enrollmentClasses, setEnrollmentClasses] = useState<EnrollmentClassType[]>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedSchool, setSelectedSchool] = useState<SchoolType>();
  const [selectedGrade, setSelectedGrade] = useState<EnrollmentGradeType>();
  const [selectedEnrollmentClass, setSelectedEnrollmentClass] = useState<EnrollmentClassType>();
  const [localSelectedTerm, setLocalSelectedTerm] = useState(term);
  const [localSelectedYear, setLocalSelectedYear] = useState(year);

  useEffect(() => {
    if (selectedSchool && selectedGrade && selectedEnrollmentClass) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSchool, selectedGrade, selectedEnrollmentClass]);

  function handleSubmitCreateEnrollment() {
    if (student
      && selectedSchool
      && selectedGrade
      && localSelectedTerm
      && localSelectedYear
      && selectedEnrollmentClass) {
      createEnrollment({
        studentId: student.studentId,
        schoolId: selectedSchool.schoolId,
        enrollmentGradeId: selectedGrade.enrollmentGradeId,
        term: localSelectedTerm,
        year: localSelectedYear,
        enrollmentClassId: selectedEnrollmentClass.enrollmentClassId,
      });
    }
  }

  useEffect(() => {
    setLocalSelectedTerm(localSelectedTerm || 'ONE');
    setLocalSelectedYear(localSelectedYear || `${(new Date()).getFullYear()}`);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    setErrorMessage(undefined);
  }, [selectedEnrollmentClass]);

  useEffect(() => {
    setSelectedEnrollmentClass(undefined);
    if (selectedGrade) {
      if (selectedGrade.enrollmentClasses) {
        setEnrollmentClasses(selectedGrade.enrollmentClasses);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(`No available classes for grade ${selectedGrade.enrollmentGradeType} at ${selectedSchool?.name}`);
      }
    }
    // eslint-disable-next-line
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedGrade(undefined);
    if (selectedSchool) {
      if (selectedSchool.enrollmentGrades) {
        setEnrollmentGrades(selectedSchool.enrollmentGrades);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(`No available grades for school ${selectedSchool.name}`);
      }
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchingSchools, errorFetchingSchools, fetchSchoolsData]);

  useEffect(() => {
    getSchools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (createEnrollmentData) {
      dispatch(closeCreateEnrollmentDialog());
    }
    // eslint-disable-next-line
  }, [createEnrollmentData]);

  function pushHistory() {
    if (register === true) {
      navigate(`/student/${application?.student?.studentId}`);
      dispatch(setRegister(false));
    }
  }

  if (fetchingSchools) return <Loading />;
  if (!schools) return <div />;
  return (
    <CoreDialog
      modalTitle="Create Enrollment"
      submitAction={() => {
        handleSubmitCreateEnrollment();
        pushHistory();
      }}
      onClose={() => dispatch(closeCreateEnrollmentDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={creatingEnrollment || fetchingSchools}
    >
      <Container maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl margin="dense" fullWidth size="medium">
              <TextField
                select
                label="Term"
                id="term"
                value={localSelectedTerm}
                variant="outlined"
              >
                { terms.map((_term) => (
                  <MenuItem
                    onClick={() => {
                      setLocalSelectedTerm(_term);
                    }}
                    value={_term}
                    key={_term}
                  >
                    {_term}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl margin="dense" fullWidth size="medium">
              <TextField
                select
                id="year-outlined"
                value={localSelectedYear}
                label="Year"
                variant="outlined"
              >
                { years.map((_year) => (
                  <MenuItem
                    onClick={() => {
                      setLocalSelectedYear(_year);
                    }}
                    value={_year}
                    key={_year}
                  >
                    {_year}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedSchool(value || undefined)}
              id="select-school-autocomplete"
              options={schools || []}
              getOptionLabel={(option) => (`${option.name}`)}
              noOptionsText="No school options: contact admin"
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedGrade(value || undefined)}
              id="select-grade-autocomplete"
              options={enrollmentGrades
                ? enrollmentGrades.filter((grade) => grade.enabled === true) : []}
              getOptionLabel={(option) => (`${option.enrollmentGradeType}`)}
              noOptionsText="No grade options: select school"
              value={selectedGrade || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select Grade" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedEnrollmentClass(value || undefined)}
              id="select-class-autocomplete"
              options={enrollmentClasses || []}
              getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
              noOptionsText="No class options: select class"
              value={selectedEnrollmentClass || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select Class" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </Container>
    </CoreDialog>
  );
}

export function CreateEnrollmentDialog({
  student, application,
}: CreateEnrollmentDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped student={student} application={application} />
    </CoreDialogProvider>
  );
}
