/* eslint-disable react/jsx-props-no-spreading */
import { Grid, TextField, Autocomplete } from '@mui/material';
import { EnrollmentClassType } from '@think-zambia-foundation/katanga-context';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { usePaymentReport } from './PaymentReportContext';

function Wrapped() {
  const {
    paymentReportData,
    paymentReportFilterDialogOpen,
    setPaymentReportFilterDialogOpen,
  } = usePaymentReport();

  const [enrollmentClasses, setEnrollmentClasses] = useState<EnrollmentClassType[]>([]);
  const [selectedEnrollmentClassFilter,
    setSelectedEnrollmentClassFilter] = useState<EnrollmentClassType>();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (selectedEnrollmentClassFilter) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedEnrollmentClassFilter]);

  useEffect(() => {
    if (paymentReportData) {
      const uniqueEnrollmentClasses:any = [];
      paymentReportData.forEach((row) => {
        if (!uniqueEnrollmentClasses.some(
          (c:any) => c.enrollmentClassId === row.enrollmentClassId,
        )) {
          uniqueEnrollmentClasses.push(
            {
              enrollmentClassId: row.enrollmentClassId,
              enrollmentClassName: row.enrollmentClassName,
            },
          );
        }
      });
      setEnrollmentClasses(uniqueEnrollmentClasses);
    }
  }, [paymentReportData]);

  useEffect(() => {
    setDialogOpen(paymentReportFilterDialogOpen);
  }, [paymentReportFilterDialogOpen]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <CoreDialog
      modalTitle="Filter Payment Report"
      submitAction={() => {
        if (selectedEnrollmentClassFilter) {
          setSearchParams({
            enrollmentClassId: selectedEnrollmentClassFilter.enrollmentClassId,
          });
        }
        setSelectedEnrollmentClassFilter(undefined);
        setPaymentReportFilterDialogOpen(false);
      }}
      onClose={() => setPaymentReportFilterDialogOpen(false)}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(event, value) => {
              setSelectedEnrollmentClassFilter(value || undefined);
            }}
            id="select-enrollment-class-autocomplete"
            options={enrollmentClasses}
            getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
            noOptionsText="No enrollment class options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Enrollment Class"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

export function PaymentReportFilterDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
