import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import BodyBackgroundColor from 'react-body-backgroundcolor';
import { StyledEngineProvider } from '@mui/material/styles';
import { KatangaProvider, katangaReportSlice, katangaSlice } from '@think-zambia-foundation/katanga-context';
import { NkanaApp, ThemeWrapper } from '@think-zambia-foundation/components';
import { LinearProgress } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import theme from './theme';
import menu from './menu';
import { routes, publicRoutes } from './routes';
import { SelectTermAndYear } from './components/SelectTermAndYear';
import KatangaLogo from './katanga-logo.png';
import { filterStudentListSlice, scholarshipSlice } from './reducer';

const katangaReducer = {
  katanga: katangaSlice.reducer,
  katangaReport: katangaReportSlice.reducer,
  filterStudentList: filterStudentListSlice.reducer,
  scholarship: scholarshipSlice.reducer,
};

function KatangaWrapper({ children }: { children: React.ReactNode }) {
  return (
    <KatangaProvider splash={(
      <div style={{
        position: 'absolute',
        left: '50%',
        top: '42%',
      }}
      >
        <img
          src={KatangaLogo}
          alt="splash"
          style={{
            height: 100,
          }}
        />
        <LinearProgress />
      </div>
    )}
    >
      {children}
    </KatangaProvider>
  );
}

ReactDOM.render(
  <ThemeWrapper theme={theme}>
    <StyledEngineProvider injectFirst>
      <BodyBackgroundColor backgroundColor="#f1f1f1">
        <NkanaApp
          wrapper={KatangaWrapper}
          app={{
            name: 'Katanga',
            loginPath: '/login',
            forgotPasswordPath: '/forgot-password',
            menu,
            routes,
            publicRoutes,
            maxWidth: 'xl',
            settings: {
              link: '/settings',
              icon: <SettingsIcon />,
              text: 'School Settings',
            },
            additionalToolbarActions: (
              <SelectTermAndYear />
            ),
          }}
          reducer={katangaReducer}
        />
      </BodyBackgroundColor>
    </StyledEngineProvider>
  </ThemeWrapper>,
  document.getElementById('root'),
);
