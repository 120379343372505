/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ScholarshipSlice {
  scholarshipId: string
}

export const scholarshipSlice = createSlice({
  name: 'scholarship',
  initialState: {
    scholarshipId: '',
  },
  reducers: {
    setScholarshipId: (state, action: PayloadAction<string>) => { state.scholarshipId = action.payload; },
  },
});

export const {
  setScholarshipId,
} = scholarshipSlice.actions;

export default scholarshipSlice.reducer;
