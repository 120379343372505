/* eslint-disable indent */
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Switch,
  Tooltip,
} from '@mui/material';
import { CollapsibleCard, SmartTypography, TableList } from '@think-zambia-foundation/components';
import {
  // EnrollmentGradeType,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import React, { useEffect, useState } from 'react';
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { ArchiveTwoTone, EditTwoTone } from '@mui/icons-material';
import { TableMetadataType, useAuth, useCore } from '@think-zambia-foundation/context';
import { SchoolFeesCard } from './SchoolFeesCard';
import { ScholarshipCard } from './ScholarshipCard';
import { SchoolInformationCardProps } from './SchoolDetails.types';

export function SchoolInformationCard({ school }: SchoolInformationCardProps) {
  const [searchParams] = useSearchParams();
  const selectedGradeId = searchParams.get('selectedGradeId');
  const { hasPermissionToClaim } = useAuth();

  const {
    updateEnrollmentGrade,
    successUpdatingEnrollmentGrade,
  } = useKatanga();

  const {
    archiveOrganization,
    archivingOrganization,
  } = useCore();

  const navigate = useNavigate();

  function handleSelectGrade(enrollmentGradeId: string) {
    if (!enrollmentGradeId) return;
    navigate(`/school/${school.schoolId}/?selectedGradeId=${enrollmentGradeId}`);
  }

  const toggleEnableSchoolGrade = (enrollmentGradeId: string, enabled: boolean) => {
    updateEnrollmentGrade({ enrollmentGradeId, enabled });
  };

  const [selectedCell, setSelectedCell] = useState<Map<string, boolean>>();
  useEffect(() => {
    if (selectedGradeId) {
      const selected = new Map<string, boolean>();
      selected.set(selectedGradeId, true);
      setSelectedCell(selected);
    }
  }, [selectedGradeId]);

  // const [grades, setGrades] = useState<EnrollmentGradeType[]>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  // const [_school, setSchool] = useState<SchoolType>();
  const [loading, setLoading] = useState<Map<string, boolean>>();
  const [updateSuccess, setUpdateSuccess] = useState<Map<string, boolean>>();
  const [tableMetadata, setTableMetadata] = useState<TableMetadataType>();
  useEffect(() => {
    if (school?.enrollmentGrades) {
      // setGrades(school.enrollmentGrades);
      // setSchool(school);
      setTableMetadata({
        tableKey: 'enrollmentGradeId',
        onClickCell: (enrollmentGradeId) => {
          handleSelectGrade(enrollmentGradeId);
        },
        secondaryActions: [{
          key: '2ca2e4e142a9463e94af549ee459bb6f',
          callback: (enrollmentGrade) => {
            const newMap = new Map(loading || []);
            newMap.set(enrollmentGrade.enrollmentGradeId, true);
            setLoading(newMap);
            toggleEnableSchoolGrade(enrollmentGrade.enrollmentGradeId, !enrollmentGrade.enabled);
          },
          icon: (grade) => <Switch checked={grade.enabled === true} />,
        }],
        cells: [{
          key: 'enrollmentGradeType',
          header: 'Enrollment Grade',
          variant: 'name',
        }],
      });
    }
  }, [school, selectedCell]);

  useEffect(() => {
    if (successUpdatingEnrollmentGrade?.updateEnrollmentGrade) {
      const newMap = new Map(updateSuccess || []);
      newMap.set(successUpdatingEnrollmentGrade.updateEnrollmentGrade.enrollmentGradeId, true);
      setUpdateSuccess(newMap);
    }
  }, [successUpdatingEnrollmentGrade]);

  useEffect(() => {
    const newMap = new Map(updateSuccess || []);
    updateSuccess?.forEach((value, enrollmentGradeId) => {
      newMap.delete(enrollmentGradeId);
      loading?.delete(enrollmentGradeId);
    });
    setUpdateSuccess(newMap);
  }, [school]);

  if (!school) return <div />;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <CollapsibleCard
          title="School Information"
          actions={[
            {
              key: '939d9cd67dde4ea9b7653404057a91ab',
              callback: () => {},
              icon: <Tooltip title="Edit School"><EditTwoTone /></Tooltip>,
              text: 'Edit',
            },
            {
              key: 'de90f14cf0478cfe36c02d6ddf616e20',
              callback: () => {
                archiveOrganization({ organizationId: school.schoolId })
                  .then((archiveOrganizationData) => {
                    if (archiveOrganizationData) {
                      navigate('/settings');
                    }
                  });
              },
              icon: <Tooltip title="Archive School"><ArchiveTwoTone /></Tooltip>,
              text: 'Archive',
              disableCard: !(hasPermissionToClaim('katangaSchoolAdmin')),
            },
          ]}
          loading={archivingOrganization}
        >
          <List dense>
            <ListItem>
              <ListItemText>
                <SmartTypography
                  caption="School Name"
                  text={school.name}
                  variant="name"
                />
              </ListItemText>
            </ListItem>
          </List>
        </CollapsibleCard>
      </Grid>
      <Grid item xs={12}>
        <SchoolFeesCard title="School Fees" fees={school.fees} referenceId={school.schoolId} />
      </Grid>
      <Grid item xs={12}>
        <ScholarshipCard
          title="School Scholarships"
          referenceId={school.schoolId}
          scholarships={school.availableScholarships}
        />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleCard
          title="Select Grade"
        >
          <TableList
            data={school.enrollmentGrades || []}
            disableSearch
            hideCSVDownload
            hidePagination
            hideHeader
            selected={selectedCell}
            loading={loading}
            elevation={0}
            tableMetadata={tableMetadata}
          />
        </CollapsibleCard>
      </Grid>
    </Grid>
  );
}
