/* eslint-disable react/jsx-props-no-spreading */
import {
  TextField, Autocomplete, FormGroup, FormControlLabel, Switch,
} from '@mui/material';
import React, { useEffect } from 'react';
import { closeFilterStudentListDialog, KatangaSlice } from '@think-zambia-foundation/katanga-context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useSelector, useDispatch } from 'react-redux';
import {
  FilterStudentListSlice,
  setFilterEnrolled,
  setFilterNotEnrolled,
  setStatus,
} from '../../reducer';

const StatusOptions = [
  'ACTIVE',
  'SUSPENDED',
];

function Wrapped() {
  const dispatch = useDispatch();
  const filterStudentListDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.filterStudentListDialogOpen,
  );
  const status = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.status,
  );
  const enrolled = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.enrolled,
  );
  const notEnrolled = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.notEnrolled,
  );

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  useEffect(() => {
    setDialogOpen(filterStudentListDialogOpen);
  }, [filterStudentListDialogOpen]);

  return (
    <CoreDialog
      modalTitle="Filter Student List"
      submitAction={() => {
        dispatch(closeFilterStudentListDialog());
      }}
      onClose={() => {
        dispatch(closeFilterStudentListDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={false}
    >
      <Autocomplete
        onChange={(event, value) => {
          if (value) {
            dispatch(setStatus(value));
          }
        }}
        id="select-studentStatus-autocomplete"
        options={StatusOptions || ''}
        value={status || ''}
        noOptionsText="No Student Status options: contact admin"
        style={{ width: 300, marginBottom: '2em' }}
        renderInput={(params) => <TextField {...params} label="Select Student Status" variant="outlined" />}
      />
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={enrolled}
              onChange={(event) => dispatch(setFilterEnrolled(event.target.checked))}
            />
          )}
          label="Filter Enrolled Students"
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={notEnrolled}
              onChange={(event) => dispatch(setFilterNotEnrolled(event.target.checked))}
            />
          )}
          label="Filter Unenrolled Students"
        />
      </FormGroup>
    </CoreDialog>
  );
}

export function StudentListFilterDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
