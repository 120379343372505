import React, {
  createContext, useContext, useState,
} from 'react';
import { SchoolType } from '@think-zambia-foundation/katanga-context';
import { EnrollmentReportContextType, EnrollmentReportProviderProps } from './EnrollmentReport.types';

const EnrollmentReportFilterContext = createContext<EnrollmentReportContextType>(undefined!);

export function useEnrollmentReportFilter() {
  return useContext(EnrollmentReportFilterContext);
}

export function EnrollmentReportFilterProvider({
  children,
}:EnrollmentReportProviderProps) {
  const [schoolFilter, setSchoolFilter] = useState<SchoolType>();
  const [gradeFilter, setGradeFilter] = useState<string>();
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState<string>();

  return (
    <EnrollmentReportFilterContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        schoolFilter,
        gradeFilter,
        enrollmentClassFilter,
        setSchoolFilter,
        setGradeFilter,
        setEnrollmentClassFilter,
      }}
    >
      {children}
    </EnrollmentReportFilterContext.Provider>
  );
}
