import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { GET_STUDENT_BY_ID, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import {
  setTitle, useAuth, useChangeHistory, useCore,
} from '@think-zambia-foundation/context';
import { useParams } from 'react-router-dom';
import { Loading } from '@think-zambia-foundation/components';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import {
  StudentPaymentHistory,
  StudentEnrollmentInfo,
  StudentInfo,
  GuardianContactInfo,
  StudentContactInfo,
  StudentEnrollmentSubjectReport,
} from '../../components';

function LoadingComponent() {
  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
    }}
    >
      <Loading />
    </div>
  );
}

function Wrapped({ studentId }: { studentId: string }) {
  const { setChangeHistoryReferenceId } = useChangeHistory();

  const dispatch = useDispatch();
  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  const {
    createEnrollmentData,
    updateStudentData,
    updateEnrollmentData,
    updateGuardianData,
    generateStudentLoginData,
    applyScholarshipData,
    revokeAppliedScholarshipData,
    katangaClient,
    updateEnrollmentSubjectData,
  } = useKatanga();

  const {
    loading: fetchingStudentById,
    // error: errorFetchingStudentById,
    data: fetchStudentByIdData,
    refetch,
  } = useQuery(GET_STUDENT_BY_ID, {
    notifyOnNetworkStatusChange: true,
    client: katangaClient,
    pollInterval: 0,
    variables: {
      studentId,
      term,
      year,
    },
  });

  useEffect(() => {
    if (fetchStudentByIdData?.student) {
      dispatch(setTitle(`${fetchStudentByIdData.student.firstName || ''} ${fetchStudentByIdData.student.lastName || ''}`));
      setChangeHistoryReferenceId(fetchStudentByIdData.student.studentId);
    }
  }, [fetchStudentByIdData]);

  const {
    addPaymentData,
    updateContactData,
  } = useCore();

  useEffect(() => {
    if (createEnrollmentData?.createStudentEnrollment
      || applyScholarshipData?.applyScholarship
      || revokeAppliedScholarshipData?.removeAppliedScholarship
      || updateEnrollmentData?.updateEnrollment
      || updateStudentData?.updateStudent
      || updateGuardianData?.updateGuardianInformation
      || generateStudentLoginData?.generateStudentLogin
      || addPaymentData
      || updateEnrollmentSubjectData?.updateEnrollmentSubject) {
      if (studentId && term && year) {
        refetch();
      }
    }
  }, [
    updateStudentData,
    updateGuardianData,
    updateEnrollmentData,
    updateContactData,
    createEnrollmentData,
    generateStudentLoginData,
    applyScholarshipData,
    revokeAppliedScholarshipData,
    addPaymentData,
    updateEnrollmentSubjectData,
  ]);

  const {
    hasPermissionToClaim,
    currentUser,
    loadingCurrentUser,
  } = useAuth();

  if (loadingCurrentUser || fetchingStudentById) {
    return (
      <LoadingComponent />
    );
  }

  if (!hasPermissionToClaim('katangaStudentUser') && currentUser?.uid !== fetchStudentByIdData.student.uid) {
    return (
      <Typography>
        You do not have permissions to this page. contact the system admin
      </Typography>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12}>
        {fetchingStudentById && (<LoadingComponent />)}
      </Grid>
      <Grid item xs={12} sm={4}>
        <StudentInfo student={fetchStudentByIdData.student} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <StudentContactInfo student={fetchStudentByIdData.student} />
          </Grid>
          {fetchStudentByIdData.student.guardian && (
          <Grid item xs={12}>
            <GuardianContactInfo student={fetchStudentByIdData.student} />
          </Grid>
          )}
          <Grid item xs={12}>
            <StudentEnrollmentInfo student={fetchStudentByIdData.student} />
          </Grid>
          {fetchStudentByIdData.student?.enrollment?.enrollmentSubjects && (
          <Grid item xs={12}>
            <StudentEnrollmentSubjectReport student={fetchStudentByIdData.student} />
          </Grid>
          )}
          <Grid item xs={12}>
            <StudentPaymentHistory student={fetchStudentByIdData.student} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export function StudentDetails() {
  const { studentId } = useParams();

  if (!studentId) return <div />;
  return (
    <Wrapped studentId={studentId} />
  );
}
