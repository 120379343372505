import { PaymentReportType } from '@think-zambia-foundation/katanga-context';
import React, {
  useState, createContext, useContext,
} from 'react';
import { PaymentReportContextType, PaymentReportProviderProps } from './PaymentReportContext.types';

const PaymentReportContext = createContext<PaymentReportContextType>(undefined!);

export function usePaymentReport() {
  return useContext(PaymentReportContext);
}

export function PaymentReportProvider({
  children,
}: PaymentReportProviderProps){
  const [paymentReportData, setPaymentReportData] = useState<PaymentReportType[]>();
  const [filteredPaymentReportData, setFilteredPaymentReportData] = useState<PaymentReportType[]>();
  const [paymentReportFilterDialogOpen, setPaymentReportFilterDialogOpen] = useState(false);
  const [enrollmentClassFilter, setEnrollmentClassFilter] = useState<string>();
  const [gradeFilter, setGradeFilter] = useState<string>();
  const [schoolFilter, setSchoolFilter] = useState<string>();

  return (
    <PaymentReportContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        paymentReportData,
        paymentReportFilterDialogOpen,
        enrollmentClassFilter,
        filteredPaymentReportData,
        gradeFilter,
        schoolFilter,
        setPaymentReportData,
        setPaymentReportFilterDialogOpen,
        setEnrollmentClassFilter,
        setFilteredPaymentReportData,
        setGradeFilter,
        setSchoolFilter,
      }}
    >
      {children}
    </PaymentReportContext.Provider>
  );
}
