import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { GuardianInformationRegistrationFormProps } from './GuardianInformationRegistrationForm.types';

const PREFIX = 'GuardianInformationRegistrationForm';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    minWidth: 275,
  },
});

export function GuardianInformationRegistrationForm({
  guardian,
  setGuardianId,
  setGuardianFirstName,
  setGuardianLastName,
}: GuardianInformationRegistrationFormProps) {
  return (
    <Root>
      <Card className={classes.root} variant="outlined" square>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Typography color="textSecondary" gutterBottom>
                Guardian Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-id"
                label="Guardian NRC"
                value={guardian.guardianId}
                onChange={(e) => setGuardianId(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-first-name"
                label="Guardian First Name"
                value={guardian.firstName}
                onChange={(e) => setGuardianFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="guardian-last-name"
                label="Guardian Last Name"
                value={guardian.lastName}
                onChange={(e) => setGuardianLastName(e.target.value)}
              />
            </Grid>
            <Grid item sm={3} />
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}
