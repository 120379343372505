import React from 'react';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Hidden from '@mui/material/Hidden';
import { Grid } from '@mui/material';
import { StudentDetailsToolbarProps } from './StudentDetailsToolbar.types';

const PREFIX = 'StudentDetailsToolbar';

const classes = {
  link: `${PREFIX}-link`,
};

const StyledToolbar = styled(Toolbar)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: 'inherit',
  },
}));

export function StudentDetailsToolbar({ student }: StudentDetailsToolbarProps) {
  return (
    <StyledToolbar>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Hidden only="xs">
          <Grid item>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link to="/student" className={classes.link}>
                <Typography variant="body2" color="primary">
                  Students
                </Typography>
              </Link>
              <Typography variant="body2" color="textSecondary">
                {student?.studentNumber || ''}
              </Typography>
            </Breadcrumbs>
          </Grid>
        </Hidden>
      </Grid>
    </StyledToolbar>
  );
}
