import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { ApplicationType, useKatanga } from '@think-zambia-foundation/katanga-context';
import { useAuth } from '@think-zambia-foundation/context';
import { useParams } from 'react-router-dom';
import { Loading } from '@think-zambia-foundation/components';
import { ApplicationInfo, StudentContactInfo } from '../../components';

export function ApplicationDetails() {
  const { applicationId } = useParams();
  const [application, setApplication] = useState<ApplicationType>();
  const {
    archiveApplicationData,
    rejectApplicationData,
    getApplication,
    fetchApplicationByIdData,
    fetchingApplicationById,
  } = useKatanga();

  useEffect(() => {
    if (applicationId) {
      getApplication({ applicationId });
    }
  }, [applicationId]);

  useEffect(() => {
    if (archiveApplicationData?.archiveApplication && applicationId) {
      getApplication({ applicationId });
    }
  }, [archiveApplicationData]);

  useEffect(() => {
    if (rejectApplicationData?.rejectStudent && applicationId) {
      getApplication({ applicationId });
    }
  }, [rejectApplicationData]);

  useEffect(() => {
    if (fetchApplicationByIdData?.application) {
      setApplication(fetchApplicationByIdData.application);
    }
  }, [fetchApplicationByIdData]);

  const { hasPermissionToClaim } = useAuth();

  if (fetchingApplicationById) return <Loading fullHeight />;
  if (!application) return <div />;
  if (!hasPermissionToClaim('katangaStudentsAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  if (application) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}>
          <ApplicationInfo application={application} />
        </Grid>
        {fetchApplicationByIdData?.application?.student && (
        <Grid item xs={12} sm={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <StudentContactInfo student={application.student} />
            </Grid>
          </Grid>
        </Grid>
        )}
      </Grid>
    );
  }
}
