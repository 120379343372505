/* eslint-disable max-len */
import React from 'react';
import {
  Grid,
} from '@mui/material';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { useKatanga } from '@think-zambia-foundation/katanga-context';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useAuth } from '@think-zambia-foundation/context';

export function BulkOperations() {
  const {
    runBackFillContactDetailsScript,
    runBackFillStudentApplicationIdScript,
    runBackFillStudentNumberScript,
    runBackFillContactDetailsScriptData,
    runBackFillStudentApplicationIdScriptData,
    runBackFillStudentNumberScriptData,
    runningBackFillContactDetailsScript,
    runningBackFillStudentApplicationIdScript,
    runningBackFillStudentNumberScript,
    errorRunningBackFillStudentApplicationIdScript,
    errorRunningBackFillStudentNumberScript,
    errorRunningBackFillContactDetailsScript,
  } = useKatanga();

  const data = [
    {
      id: '1',
      title: 'Backfill Contact Details',
      function: runBackFillContactDetailsScript,
      loading: runningBackFillContactDetailsScript,
      data: runBackFillContactDetailsScriptData,
      error: errorRunningBackFillContactDetailsScript,
    }, {
      id: '2',
      title: 'Backfill Student Application Id',
      function: runBackFillStudentApplicationIdScript,
      loading: runningBackFillStudentApplicationIdScript,
      data: runBackFillStudentApplicationIdScriptData,
      error: errorRunningBackFillStudentApplicationIdScript,
    }, {
      id: '3',
      title: 'Backfill Student Number',
      function: runBackFillStudentNumberScript,
      loading: runningBackFillStudentNumberScript,
      data: runBackFillStudentNumberScriptData,
      error: errorRunningBackFillStudentNumberScript,
    }];

  const { hasPermissionToClaim } = useAuth();

  if (!hasPermissionToClaim('katangaAdmin')) return <>You don&apos;t have permission to view this page!!!</>;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={4}>
        <CollapsibleCard
          title="Bulk Operations"
        >
          <TableList
            data={data}
            disableSearch
            hideCSVDownload
            hidePagination
            hideHeader
            elevation={0}
            tableMetadata={{
              tableKey: 'function',
              onClickCell: (bulkOperationFunction) => { if (bulkOperationFunction) bulkOperationFunction(); },
              secondaryActions: [{
                key: '2ca2e4e142a9463e94af549ee459bb6f',
                callback: () => {},
                icon: (iconData) => {
                  if (iconData?.data) return <DoneTwoToneIcon />;
                  if (iconData?.error) return <CloseTwoToneIcon />;
                  if (iconData?.loading) return 'Running';
                  return null;
                },
              }],
              cells: [{
                key: 'title',
                variant: 'name',
              }],
            }}
          />
        </CollapsibleCard>
      </Grid>
    </Grid>
  );
}
