/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  openCreateScholarshipDialog, openScholarshipDetailsDialog, openUpdateScholarshipDialog, setScholarshipReferenceId, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditTwoTone } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { ScholarshipCardProps } from './ScholarshipCard.types';
import { UpdateScholarshipDialog } from '../../components';
import { setScholarshipId } from '../../reducer';

export function ScholarshipCard({ scholarships, title, referenceId }: ScholarshipCardProps) {
  const {
    creatingScholarship,
    fetchingScholarshipsByReferenceId,
    removeScholarship,
    removingScholarship,
    updatingScholarship,
  } = useKatanga();

  const dispatch = useDispatch();

  const handleAddScholarship = () => {
    dispatch(setScholarshipReferenceId(referenceId));
    dispatch(openCreateScholarshipDialog());
  };

  function handleSelectedScholarship(scholarshipId: string) {
    dispatch(setScholarshipReferenceId(scholarshipId));
    dispatch(openScholarshipDetailsDialog());
  }

  if (!scholarships) return <div />;
  return (
    <>
      <CollapsibleCard
        title={title}
        actions={[
          {
            key: 'ff0d0910eba9495ab8b8c4cabae5ff16',
            callback: handleAddScholarship,
            icon: <AddTwoToneIcon />,
          },
        ]}
        loading={creatingScholarship || fetchingScholarshipsByReferenceId || removingScholarship || updatingScholarship}
      >
        <TableList
          data={scholarships || []}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
          elevation={0}
          tableMetadata={{
            href: '/scholarships',
            tableKey: 'scholarshipId',
            onClickCell: (scholarshipId) => {
              // eslint-disable-next-line no-empty
              handleSelectedScholarship(scholarshipId);
            },
            secondaryActions: [{
              key: '79c3c4b748a44733a7e791e0dfc9e6ee',
              callback: (scholarship) => {
                dispatch(openUpdateScholarshipDialog());
                dispatch(setScholarshipId(scholarship.scholarshipId));
              },
              icon: () => <EditTwoTone />,
              text: 'Update',
              disableCard: () => false,
            }, {
              key: '8b82b3a7b8bb469d9741e41b2231c9da',
              callback: (scholarship) => { removeScholarship(scholarship); },
              icon: () => <DeleteIcon />,
              text: 'Delete',
              disableCard: () => false,
            }],
            cells: [{
              key: 'name',
              header: 'Scholarship',
              variant: 'name',
            }],
          }}
        />
      </CollapsibleCard>
      <UpdateScholarshipDialog />
    </>
  );
}
