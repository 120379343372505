import React, { useEffect, useState } from 'react';
import {
  KatangaSlice,
  openUpdateEnrollmentSubjectDialog,
  setUpdateEnrollmentSubjectId,
  StudentType,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import {
  TableList, CollapsibleCard,
} from '@think-zambia-foundation/components';
import {
  TableListProvider, TableMetadataType, useAuth,
} from '@think-zambia-foundation/context';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateEnrollmentSubjectDialog } from '../UpdateEnrollmentSubjectDialog';
import { StudentEnrollmentSubjectReportProps } from './StudentEnrollmentSubjectReport.types';

function Wrapped({ tableMetadata, student }: {
  tableMetadata: TableMetadataType,
  student: StudentType,
}) {
  const {
    refetchStudentById,
  } = useKatanga();

  const updateEnrollmentSubjectDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.updateEnrollmentSubjectDialogOpen,
  );

  useEffect(() => {
    if (!updateEnrollmentSubjectDialogOpen && refetchStudentById) {
      refetchStudentById();
    }
  }, [updateEnrollmentSubjectDialogOpen]);

  if (!student?.enrollment?.enrollmentSubjects) return <div />;
  return (
    <>
      <CollapsibleCard
        title="Subjects"
      >
        <TableList
          data={student.enrollment.enrollmentSubjects}
          disableSearch
          elevation={0}
          tableMetadata={tableMetadata}
          hideCSVDownload
        />
      </CollapsibleCard>
      <UpdateEnrollmentSubjectDialog />
    </>
  );
}

export function StudentEnrollmentSubjectReport({
  student,
}: StudentEnrollmentSubjectReportProps) {
  function calculateLetterGrade(score: any) {
    if (!score) return '-';
    if (score <= 59) return 'F';
    if (score <= 69) return 'D';
    if (score <= 79) return 'C';
    if (score <= 89) return 'B';
    return 'A';
  }

  const dispatch = useDispatch();
  const { hasPermissionToClaim } = useAuth();

  const [tableMetadata] = useState<TableMetadataType>({
    tableKey: 'enrollmentSubjectId',
    onClickCell: (enrollmentSubjectId) => {
      if (hasPermissionToClaim('KatangaScoreAdmin')) {
        dispatch(openUpdateEnrollmentSubjectDialog());
        dispatch(setUpdateEnrollmentSubjectId(enrollmentSubjectId));
      }
    },
    cells: [
      {
        key: 'name',
        header: 'Subject',
        value(data: any) { return data.subject.name; },
        variant: 'name',
      }, {
        key: 'studentId',
        header: 'Teacher',
        value(data: any) { return data.subject.staff ? `${data.subject.staff.firstName} ${data.subject.staff.lastName}` : ''; },
        variant: 'name',
      }, {
        key: 'schoolId',
        header: 'Score (%)',
        value(data: any) { return `${data.score || '-'}`; },
        variant: 'name',
      }, {
        key: 'gradeId',
        header: 'Grade',
        value(data: any) { return `${calculateLetterGrade(data.score)}`; },
        variant: 'name',
      }],
  });

  return (
    <TableListProvider>
      <Wrapped tableMetadata={tableMetadata} student={student} />
    </TableListProvider>
  );
}
