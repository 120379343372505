/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import { closeUpdateScholarshipDialog, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';
import { ScholarshipSlice } from '../../reducer';

function Wrapped() {
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [duration, setDuration] = useState<number>();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  const {
    updateScholarship,
    errorUpdatingScholarship,
    updatingScholarship,
  } = useKatanga();

  const dispatch = useDispatch();
  const updateScholarshipDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.updateScholarshipDialogOpen,
  );
  const scholarshipId = useSelector((state: { scholarship: ScholarshipSlice }) => state.scholarship.scholarshipId);
  useEffect(() => {
    setDialogOpen(updateScholarshipDialogOpen);
    // eslint-disable-next-line
  }, [updateScholarshipDialogOpen]);

  useEffect(() => {
    dispatch(closeUpdateScholarshipDialog());
  }, []);

  useEffect(() => {
    if (scholarshipId) {
      setSubmitDisabled(false);
    }
  }, [scholarshipId]);

  function handleSubmitAddScholarship() {
    updateScholarship({
      scholarshipId,
      name,
      amount: amount ? parseFloat(amount) : undefined,
      description,
      duration,
    });
    dispatch(closeUpdateScholarshipDialog());
  }

  if (updatingScholarship) return <Loading />;
  if (errorUpdatingScholarship) return <>Error creating scholarship</>;
  return (
    <CoreDialog
      modalTitle="Update Scholarship"
      submitAction={() => handleSubmitAddScholarship()}
      onClose={() => {
        dispatch(closeUpdateScholarshipDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              label="Scholarship Name"
              variant="outlined"
              name="Name"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              type="number"
              id="amount-tf"
              onChange={(event) => setAmount(event.target.value)}
              variant="outlined"
              margin="normal"
              label="Amount"
              name="Amount"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="duration-tf"
              onChange={(event) => {
                if (event.target.value) {
                  setDuration(parseInt(event.target.value, 10));
                }
              }}
              variant="outlined"
              margin="normal"
              label="Duration"
              placeholder="How long is the scholarship valid? (acadmic year)"
              name="Duration"
              InputProps={{ type: 'number' }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              label="Scholarship Description"
              name="Scholarship Description"
              id="Scholarship-description-tf"
              onChange={(event) => setDescription(event.target.value)}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdateScholarshipDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
