/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import {
  Button,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  SmartTypography, CollapsibleCard, TableList,
} from '@think-zambia-foundation/components';
import {
  FeeType,
  openApplyScholarshipDialog,
  openMakePaymentOnlineDialog,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { PaymentType, useAuth, useCore } from '@think-zambia-foundation/context';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { EmojiEventsOutlined, LoginOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { MakePaymentDialog } from '../MakePaymentDialog';
import { ApplyScholarshipDialog } from '../ApplyScholarshipDialog';
import { ScholarshipDetailsDialog } from '../ScholarshipDetailsDialog';
import { StudentInfoProps } from './StudentInfo.types';

export function StudentInfo({ student }: StudentInfoProps) {
  const {
    applyingScholarship,
    applyScholarshipData,
    revokingAppliedScholarship,
    generateStudentLogin,
    generateStudentLoginData,
    getStudentById,
    revokeAppliedScholarship,
    revokeAppliedScholarshipData,
    archiveStudent,
    archivingStudent,
  } = useKatanga();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAge = (birthDate: any) => Math.floor(
    ((new Date().getTime()) - new Date(birthDate).getTime()) / 3.154e+10,
  );
  const { hasPermissionToClaim } = useAuth();

  const {
    addPayment,
    addPaymentData,
  } = useCore();

  useEffect(() => {
    if (generateStudentLoginData?.generateStudentLogin?.studentId) {
      getStudentById(generateStudentLoginData.generateStudentLogin.studentId);
    }
  }, [generateStudentLoginData]);

  const [balanceOwed, setBalanceOwed] = useState(0);

  useEffect(() => {
    if (student && student.enrollment) {
      const totalAmountDue = student.enrollment.fees
        .reduce((total: number, fee: FeeType) => total + fee.amount, 0);
      const totalAmountPaid = student.enrollment.payments
        .reduce((total: number, payment: PaymentType) => total + payment.paymentAmount, 0);
      setBalanceOwed(totalAmountDue - totalAmountPaid);
    } else {
      setBalanceOwed(0);
    }
  }, [student, applyScholarshipData, addPaymentData, revokeAppliedScholarshipData]);

  const { currentUser } = useAuth();

  if (!student) return <div />;
  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={12} xs={12}>
        <CollapsibleCard
          title="Student Information"
          actions={[
            {
              key: '677696a512b748f488374fb56d89f619',
              callback: () => {
                if ((hasPermissionToClaim('katangaStudentsAdmin'))) {
                  archiveStudent({ studentId: student.studentId })
                    .then((data: any) => {
                      if (data) {
                        navigate('/student');
                      }
                    });
                }
              },
              icon: <ArchiveOutlinedIcon />,
              text: 'Achieve Student',
              disableCard: !hasPermissionToClaim('katangaStudentsAdmin'),
            },
            {
              key: '87132910d01b435b8b01609abed283c8',
              callback: () => {
                if ((!student.uid && hasPermissionToClaim('katangaAdmin')) && student?.guardian?.contact?.email) {
                  if (student.guardian && student.guardian?.contact?.email) {
                    generateStudentLogin({ studentId: student.studentId });
                  }
                }
              },
              text: (!student.uid && student?.guardian?.contact?.email && hasPermissionToClaim('katangaAdmin')) ? ' Generate Login' : 'Add Contact Email',
              icon: (
                <Tooltip
                  title={(student.guardian && student?.guardian?.contact?.email)
                    ? '' : 'Add guardian email to enable this button'}
                  aria-label="add"
                >
                  <LoginOutlined />
                </Tooltip>),
              disableCard: !!student.uid || (!hasPermissionToClaim('katangaAdmin')),
            },
          ]}
          loading={archivingStudent}
        >
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SmartTypography
                  caption="Name"
                  text={`${student.firstName} ${student.lastName}`}
                  variant="name"
                  gutterBottom
                />
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} />
              <Grid item xs={12}>
                <SmartTypography
                  caption="Gender"
                  text={`${student.gender}`}
                  gutterBottom
                />
              </Grid>
              <Grid item xs={12}>
                <SmartTypography
                  caption="Age"
                  text={`${getAge(student.dateOfBirth)}`}
                  gutterBottom
                />
              </Grid>
              {student.studentStatus !== 'PRE_REGISTERED' && (
                <>
                  <Grid item xs={12}>
                    <SmartTypography
                      caption="Status"
                      text={`${student.studentStatus}`}
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SmartTypography
                      caption="Student Number"
                      text={`${student.studentNumber}`}
                      gutterBottom
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <SmartTypography
                  caption="Balance"
                  text={`${balanceOwed}`}
                  gutterBottom
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                {student.enrollment && (
                  <>
                    <Button
                      color="secondary"
                      variant="contained"
                      style={{ borderRadius: 0 }}
                      onClick={() => dispatch(openMakePaymentOnlineDialog())}
                      fullWidth
                    >
                      Make Payment Online
                    </Button>
                    <MakePaymentDialog
                      schoolId={student.enrollment.enrollmentClass.enrollmentGrade.school.schoolId}
                      studentId={student.studentId}
                      referenceId={student.enrollment.enrollmentId}
                      options={student.enrollment.fees.map((fee) => ({
                        option: fee.feeType.toLowerCase() === 'other'
                          ? `${fee.feeType} (${fee.description})`
                          : `${fee.feeType}`,
                        amount: fee.amount,
                      }))}
                      freeSolo={false}
                    />
                  </>
                )}
              </Grid>
              <ApplyScholarshipDialog student={student} />
            </Grid>
          </CardContent>
        </CollapsibleCard>
      </Grid>
      {student.enrollment && (student?.enrollment?.appliedScholarships?.length > 0 || hasPermissionToClaim('studentScholarshipAdmin')) && (
        <Grid item sm={12} md={12} xs={12}>
          {(applyingScholarship || revokingAppliedScholarship) && (<LinearProgress />)}
          <CollapsibleCard
            title="Applied Scholarships"
            actions={[
              {
                key: '5ecedfef72c746d7bbe222f8bbb2c1de',
                callback: () => {
                  if (student && student.enrollment) {
                    dispatch(openApplyScholarshipDialog());
                  }
                },
                text: student.enrollment ? 'Award Student Scholarship' : '',
                icon: (
                  <Tooltip
                    title="Award scholarship"
                    aria-label="add"
                  >
                    <EmojiEventsOutlined />
                  </Tooltip>
                ),
                disableCard: !(hasPermissionToClaim('studentScholarshipAdmin')),
              },
            ]}
          >
            <TableList
              data={student?.enrollment?.appliedScholarships || []}
              disableSearch
              hideCSVDownload
              hidePagination
              hideHeader
              elevation={0}
              tableMetadata={{
                href: '/scholarships',
                tableKey: 'appliedScholarshipId',
                onClickCell: () => { },
                secondaryActions: [{
                  key: 'c47158cfed44460c886ef66a93801439',
                  callback: (appliedScholarship) => {
                    revokeAppliedScholarship({
                      appliedScholarshipId: appliedScholarship.appliedScholarshipId,
                    }).then(({ data }) => {
                      addPayment({
                        referenceId: data?.removeAppliedScholarship?.enrollmentId,
                        note: data?.removeAppliedScholarship?.scholarship?.name || '',
                        paymentInvoice: data?.removeAppliedScholarship?.appliedScholarshipId,
                        paymentAmount: -parseFloat(
                          data?.removeAppliedScholarship?.scholarship?.amount,
                        ),
                        paymentMadeBy: `revoke scholarship (${data?.removeAppliedScholarship?.scholarship?.name})`,
                        paymentReceivedBy: currentUser?.displayName || 'admin',
                      });
                    });
                  },
                  icon: () => <Tooltip title="Revoke scholarship"><CloseIcon /></Tooltip>,
                  text: 'Revoke',
                  disableCard: () => !(hasPermissionToClaim('studentScholarshipAdmin')),
                }],
                cells: [{
                  key: 'appliedScholarshipId',
                  variant: 'name',
                  value(data) {
                    return `${data?.scholarship?.name} (${data?.scholarship?.amount})`;
                  },
                }],
              }}
            />
          </CollapsibleCard>
          <ScholarshipDetailsDialog />
        </Grid>
      )}
    </Grid>
  );
}
