/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container, Grid, TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  closeApplyScholarshipDialog, KatangaSlice, ScholarshipType, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import {
  useCoreDialog, CoreDialogProvider,
  useCore,
  useAuth,
} from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';
import { ApplyScholarshipDialogProps } from './ApplyScholarshipDialog.types';

function Wrapped({
  student,
}: ApplyScholarshipDialogProps) {
  const [selectedScholarship, setSelectedScholarship] = useState<ScholarshipType>();
  const [availableScholarships, setAvailableScholarships] = useState<ScholarshipType[]>();
  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();
  const dispatch = useDispatch();

  const {
    applyScholarship,
    fetchAvailableScholarshipsData,
    getAvailableScholarships,
    fetchingAvailableScholarships,
  } = useKatanga();

  const applyScholarshipDialogOpen = useSelector(
    (state:{ katanga:KatangaSlice }) => state.katanga.applyScholarshipDialogOpen,
  );

  const {
    addPayment,
    addPaymentData,
  } = useCore();

  const {
    currentUser,
  } = useAuth();

  useEffect(() => {
    if (student.enrollment) {
      getAvailableScholarships({
        schoolId: student?.enrollment?.enrollmentClass?.enrollmentGrade?.school?.schoolId,
        enrollmentClassId: student?.enrollment?.enrollmentClass?.enrollmentClassId,
        enrollmentGradeId: student?.enrollment?.enrollmentClass?.enrollmentGrade?.enrollmentGradeId,
      });
    }
  }, [student]);

  useEffect(() => {
    if (fetchAvailableScholarshipsData?.availableScholarships) {
      setAvailableScholarships(fetchAvailableScholarshipsData.availableScholarships);
    }
  }, [fetchAvailableScholarshipsData]);

  useEffect(() => {
    if (selectedScholarship) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedScholarship]);

  useEffect(() => {
    setSelectedScholarship(undefined);
    setDialogOpen(applyScholarshipDialogOpen);
  }, [applyScholarshipDialogOpen]);

  useEffect(() => {
    if (addPaymentData) {
      dispatch(closeApplyScholarshipDialog());
    }
  }, [addPaymentData]);

  function handleSubmitApplyScholarship() {
    applyScholarship({
      studentId: student.studentId,
      enrollmentId: student?.enrollment?.enrollmentId,
      scholarshipId: selectedScholarship?.scholarshipId,
    }).then(({ data: applyScholarshipData }) => {
      if (applyScholarshipData?.applyScholarship) {
        if (selectedScholarship?.amount) {
          addPayment({
            referenceId: applyScholarshipData?.applyScholarship?.enrollmentId,
            note: selectedScholarship?.name || '',
            paymentInvoice: applyScholarshipData?.applyScholarship?.appliedScholarshipId,
            paymentAmount: parseFloat(selectedScholarship?.amount),
            paymentMadeBy: `scholarship (${selectedScholarship?.name})`,
            paymentReceivedBy: currentUser?.displayName || 'admin',
          });
        }
      }
    });
    dispatch(closeApplyScholarshipDialog());
  }

  return (
    <CoreDialog
      modalTitle="Apply Scholarship"
      submitAction={() => handleSubmitApplyScholarship()}
      onClose={() => {
        dispatch(closeApplyScholarshipDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled || fetchingAvailableScholarships}
      loading={fetchingAvailableScholarships}
    >
      <Container maxWidth="md" style={{ width: 375 }}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            {availableScholarships && (
              <Autocomplete
                onChange={(event, value) => setSelectedScholarship(value)}
                id="select-scholarship-autocomplete"
                options={availableScholarships}
                getOptionLabel={(option) => `${option.name} (K${option.amount})`}
                disableClearable
                value={selectedScholarship || undefined}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Scholarship"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </CoreDialog>
  );
}

export function ApplyScholarshipDialog({
  student,
}: ApplyScholarshipDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped student={student} />
    </CoreDialogProvider>
  );
}
