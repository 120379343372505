/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import {
  closeCreateScholarshipDialog, KatangaSlice, setScholarshipReferenceId, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [amount, setAmount] = useState<number>();
  const [capacity, setCapacity] = useState<number>();

  const dispatch = useDispatch();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  const {
    createScholarship,
    creatingScholarship,
    errorCreatingScholarship,
  } = useKatanga();

  const scholarshipReferenceId = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.scholarshipReferenceId,
  );

  const createScholarshipDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.createScholarshipDialogOpen,
  );

  // setCreateScholarshipDialogOpen,
  // setScholarshipReferenceId,

  useEffect(() => {
    if (scholarshipReferenceId
      && name
      && description
      && amount
      && capacity) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [name, description, amount, capacity]);

  useEffect(() => {
    if (createScholarshipDialogOpen === false) {
      setName(undefined);
      setDescription(undefined);
      setAmount(undefined);
      setCapacity(undefined);
    }
    setDialogOpen(createScholarshipDialogOpen);
  }, [createScholarshipDialogOpen]);

  function handleSubmitAddScholarship() {
    createScholarship({
      referenceId: scholarshipReferenceId,
      name,
      amount,
      description,
      capacity,
    });
    dispatch(setScholarshipReferenceId());
    dispatch(closeCreateScholarshipDialog());
  }

  if (errorCreatingScholarship) return <>Error creating scholarship</>;
  return (
    <CoreDialog
      modalTitle="Create Scholarship"
      submitAction={() => handleSubmitAddScholarship()}
      onClose={() => {
        dispatch(closeCreateScholarshipDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={creatingScholarship}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <TextField
              required
              label="Scholarship Name"
              variant="outlined"
              name="Name"
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="number"
              id="amount-tf"
              onChange={(event) => setAmount(parseFloat(event.target.value))}
              variant="outlined"
              margin="normal"
              label="Amount"
              name="Amount"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="capacity-tf"
              onChange={(event) => setCapacity(parseInt(event.target.value, 10))}
              variant="outlined"
              margin="normal"
              label="Capacity"
              placeholder="Capacity"
              name="Capacity"
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              label="Scholarship Description"
              name="Scholarship Description"
              id="Scholarship-description-tf"
              onChange={(event) => setDescription(event.target.value)}
              inputProps={{ maxLength: 250 }}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function CreateScholarshipDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
