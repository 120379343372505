import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  TextField,
} from '@mui/material';
import { closeChangeTuitionDialog, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const [searchParams] = useSearchParams();
  const selectedGradeId = searchParams.get('selectedGradeId');
  const [newTuitionAmount, setNewTuitionAmount] = useState<number>();
  const [confirmNewTuitionAmount, setConfirmNewTuitionAmount] = useState<number>();
  const {
    successUpdatingEnrollmentGrade,
    updateEnrollmentGrade,
    updatingEnrollmentGrade,
  } = useKatanga();

  const dispatch = useDispatch();

  const changeTuitionDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.changeTuitionDialogOpen,
  );

  const handleSubmitChangeTuition = () => {
    if (selectedGradeId) {
      updateEnrollmentGrade({
        enrollmentGradeId: selectedGradeId,
        tuition: newTuitionAmount,
      });
    }
  };

  useEffect(() => {
    if (successUpdatingEnrollmentGrade) {
      dispatch(closeChangeTuitionDialog());
    }
    // eslint-disable-next-line
  }, [successUpdatingEnrollmentGrade]);

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    setDialogOpen(changeTuitionDialogOpen);
    // eslint-disable-next-line
  }, [changeTuitionDialogOpen]);

  useEffect(() => {
    if (confirmNewTuitionAmount
      && newTuitionAmount
      && newTuitionAmount === confirmNewTuitionAmount) {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line
  }, [confirmNewTuitionAmount]);

  return (
    <CoreDialog
      modalTitle="Change Tuition"
      submitAction={() => handleSubmitChangeTuition()}
      onClose={() => dispatch(closeChangeTuitionDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={updatingEnrollmentGrade}
    >
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="new-tuition"
            label="New Tuition Amount"
            name="New Tuition Amount"
            type="number"
            inputProps={{ min: 0, style: { textAlign: 'right' } }}
            onChange={(e) => setNewTuitionAmount(parseFloat(e.target.value))}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="confirm-new-tuition"
            type="number"
            inputProps={{ min: 0, style: { textAlign: 'right' } }}
            label="Confirm New Tuition Amount"
            name="Confirm New Tuition Amount"
            style={{ marginBottom: '2em' }}
            onChange={(e) => setConfirmNewTuitionAmount(parseFloat(e.target.value))}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function ChangeTuitionDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
