/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import {
  closeScholarshipDetailsDialog, KatangaSlice, ScholarshipType, setScholarshipReferenceId, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog, SmartTypography } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
function Wrapped() {
  const [scholarship, setScholarship] = useState<ScholarshipType>();

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  const {
    fetchScholarshipByScholarshipIdData,
    getScholarshipById,
  } = useKatanga();

  const dispatch = useDispatch();

  const scholarshipDetailsDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.scholarshipDetailsDialogOpen,
  );
  const scholarshipReferenceId = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.scholarshipReferenceId,
  );

  useEffect(() => {
    if (scholarshipReferenceId) {
      getScholarshipById({ scholarshipId: scholarshipReferenceId });
    }
  }, [scholarshipReferenceId]);

  useEffect(() => {
    setDialogOpen(scholarshipDetailsDialogOpen);
  }, [scholarshipDetailsDialogOpen]);

  useEffect(() => {
    if (fetchScholarshipByScholarshipIdData?.scholarshipById) {
      setScholarship(fetchScholarshipByScholarshipIdData.scholarshipById);
    }
  }, [fetchScholarshipByScholarshipIdData]);

  return (
    <CoreDialog
      modalTitle="Scholarship Details"
      onClose={() => {
        dispatch(setScholarshipReferenceId());
        dispatch(closeScholarshipDetailsDialog());
      }}
      dialogOpen={dialogOpen}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        spacing={2}
      >
        {scholarship && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={12}>
              <SmartTypography
                caption="Name"
                text={`${scholarship.name}`}
                variant="name"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SmartTypography
                caption="Scholarship Amount"
                text={`${scholarship.amount}`}
                variant="name"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <SmartTypography
                caption="Description"
                text={`${scholarship.description}`}
                variant="paragraph"
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </CoreDialog>
  );
}

// eslint-disable-next-line react/prop-types
export function ScholarshipDetailsDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
