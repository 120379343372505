import {
  Grid,
  Typography,
  TextField,
  Container,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {
  useEffect, useState,
} from 'react';
import {
  closeUpdateEnrollmentDialog,
  EnrollmentClassType, EnrollmentGradeType, KatangaSlice, SchoolType, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateEnrollmentDialogProps } from './UpdateEnrollmentDialog.types';

function Wrapped({ enrollmentId }: UpdateEnrollmentDialogProps) {
  const {
    updateEnrollment,
    updateEnrollmentData,
    creatingEnrollment,
    getSchools,
    fetchingSchools,
    errorFetchingSchools,
    fetchSchoolsData,
  } = useKatanga();

  const dispatch = useDispatch();
  const updateEnrollmentDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.updateEnrollmentDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    setDialogOpen(updateEnrollmentDialogOpen);
    // eslint-disable-next-line
  }, [updateEnrollmentDialogOpen]);

  const [schools, setSchools] = useState<SchoolType[]>();
  const [enrollmentGrades, setEnrollmentGrades] = useState<EnrollmentGradeType[]>();
  const [enrollmentClasses, setEnrollmentClasses] = useState<EnrollmentClassType[]>();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedSchool, setSelectedSchool] = useState<SchoolType>();
  const [selectedGrade, setSelectedGrade] = useState<EnrollmentGradeType>();
  const [selectedEnrollmentClass, setSelectedEnrollmentClass] = useState<EnrollmentClassType>();

  useEffect(() => {
    if (enrollmentId && selectedSchool && selectedGrade && selectedEnrollmentClass) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedSchool, selectedGrade, selectedEnrollmentClass]);

  function handleSubmitUpdateEnrollment() {
    if (selectedEnrollmentClass) {
      updateEnrollment({
        enrollmentId,
        enrollmentClassId: selectedEnrollmentClass?.enrollmentClassId,
      });
    }
  }

  useEffect(() => {
    setErrorMessage(undefined);
  }, [selectedEnrollmentClass]);

  useEffect(() => {
    setSelectedEnrollmentClass(undefined);
    if (selectedGrade) {
      if (selectedGrade.enrollmentClasses) {
        setEnrollmentClasses(selectedGrade.enrollmentClasses);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(`No available classes for grade ${selectedGrade.enrollmentGradeType} at ${selectedSchool?.name}`);
      }
    }
    // eslint-disable-next-line
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedGrade(undefined);
    if (selectedSchool) {
      if (selectedSchool.enrollmentGrades) {
        setEnrollmentGrades(selectedSchool.enrollmentGrades);
        setErrorMessage(undefined);
      } else {
        setErrorMessage(`No available grades for school ${selectedSchool.name}`);
      }
    }
  }, [selectedSchool]);

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchingSchools, errorFetchingSchools, fetchSchoolsData]);

  useEffect(() => {
    getSchools();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (updateEnrollmentData) {
      dispatch(closeUpdateEnrollmentDialog());
    }
    // eslint-disable-next-line
  }, [updateEnrollmentData]);

  if (fetchingSchools) return <Loading />;
  if (!schools) return <div />;
  return (
    <CoreDialog
      modalTitle="Update Enrollment"
      submitAction={() => { handleSubmitUpdateEnrollment(); }}
      onClose={() => dispatch(closeUpdateEnrollmentDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={creatingEnrollment}
    >
      <Container maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedSchool(value || undefined)}
              id="select-school-autocomplete"
              options={schools || []}
              getOptionLabel={(option) => (`${option.name}`)}
              noOptionsText="No school options: contact admin"
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedGrade(value || undefined)}
              id="select-grade-autocomplete"
              options={enrollmentGrades
                ? enrollmentGrades.filter((grade) => grade.enabled === true) : []}
              getOptionLabel={(option) => (`${option.enrollmentGradeType}`)}
              noOptionsText="No grade options: select school"
              value={selectedGrade || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select Grade" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedEnrollmentClass(value || undefined)}
              id="select-class-autocomplete"
              options={enrollmentClasses || []}
              getOptionLabel={(option) => (`${option.enrollmentClassName}`)}
              noOptionsText="No class options: select class"
              value={selectedEnrollmentClass || null}
                  // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Select Class" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </Container>
    </CoreDialog>
  );
}

// eslint-disable-next-line react/prop-types
export function UpdateEnrollmentDialog({ enrollmentId }: UpdateEnrollmentDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped enrollmentId={enrollmentId} />
    </CoreDialogProvider>
  );
}
