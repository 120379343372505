import React from 'react';
import { PaymentHistoryCard } from '@think-zambia-foundation/components';
import { StudentPaymentHistoryProps } from './StudentPaymentHistory.types';

export function StudentPaymentHistory({ student }: StudentPaymentHistoryProps) {
  if (!student.enrollment) return <div />;
  return (
    <PaymentHistoryCard
      addPaymentOptions={student.enrollment.fees.map((fee) => ({
        option: fee.feeType.toLowerCase() === 'other'
          ? `${fee.feeType} (${fee.description})`
          : `${fee.feeType}`,
        amount: fee.amount,
      }))}
      referenceId={student.enrollment.enrollmentId}
      addPaymentClaim="katangaPaymentAdmin"
      payments={student.enrollment.payments}
    />
  );
}
