import React from 'react';
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PaymentRoundedIcon from '@mui/icons-material/PaymentRounded';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import AttachMoneySharpIcon from '@mui/icons-material/AttachMoneySharp';
import HomeIcon from '@mui/icons-material/Home';
import SyncIcon from '@mui/icons-material/Sync';

const menu = [
  {
    id: 'e43253102ae24388bdbdeaf632e2a3ab',
    text: 'Dashboard',
    secondaryText: 'Dashboard',
    link: '/',
    icon: <HomeIcon style={{ fontSize: '1.5em' }} color="secondary" />,
    divider: true,
  },
  {
    id: '751437dd9afa42de842ccd25a145597d',
    claim: 'katangaStudentUser',
    text: 'Students',
    secondaryText: 'active students',
    link: '/student',
    icon: <PeopleOutlineTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: 'ba37ea8f9e834651b9c7100dd2c2da67',
    claim: 'katangaStudentsAdmin',
    text: 'Applications',
    secondaryText: 'pending applications',
    link: '/application',
    icon: <AssignmentTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: '316f304af6e9418d9fff50f36bd5aa96',
    claim: 'staffAdmin',
    text: 'Staff',
    secondaryText: 'manage staff',
    link: '/staff',
    icon: <SchoolTwoToneIcon style={{ fontSize: '1.5em' }} color="secondary" />,
    divider: true,
  },
  {
    id: 'c08bfc5089214c8aa3493ba239e1f91d',
    claim: 'katangaEnrollmentReport',
    text: 'Enrollment Report',
    secondaryText: 'enrolled students by term',
    link: '/report/enrollment',
    icon: <PlaylistAddCheckRoundedIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: '30688632f5c0482bb7753aee28690d8c',
    text: 'Payment Report',
    claim: 'katangaReportAdmin',
    secondaryText: 'payment history by term',
    link: '/report/payment',
    icon: <PaymentRoundedIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: '3b6fbe7f9bf94b03a147f292ae4b3172',
    text: 'Capacity Report',
    claim: 'katangaReportAdmin',
    secondaryText: 'capacity snapshots per term',
    link: '/report/capacity',
    icon: <AttachMoneySharpIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
  {
    id: 'c08bfc5089214c8aa3493ba239e1f91d',
    text: 'Bulk Operations',
    claim: 'katangaAdmin',
    secondaryText: 'run backfill scripts',
    link: '/bulk-operations',
    icon: <SyncIcon style={{ fontSize: '1.5em' }} color="secondary" />,
  },
];

export default menu;
