/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import {
  closeUpdateGuardianDetailsDialog, GuardianType, KatangaSlice, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import {
  useCoreDialog, CoreDialogProvider, ContactType,
} from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';
import { UpdateGuardianInformationProps } from './UpdateGuardianInformation.types';

function Wrapped({ student }: UpdateGuardianInformationProps) {
  const {
    dialogOpen,
    setDialogOpen,
    setSubmitDisabled,
    submitDisabled,
  } = useCoreDialog();

  const [guardianPhone1, setPhone1] = useState<ContactType['phone1']>();
  const [guardianPhone2, setPhone2] = useState<ContactType['phone2']>();
  const [guardianEmail, setEmail] = useState<ContactType['email']>();
  const [firstName, setGuardianFirstName] = useState<GuardianType['firstName']>();
  const [lastName, setGuardianLastName] = useState<GuardianType['lastName']>();
  const [guardianNrc, setGuardianNRC] = useState<GuardianType['guardianNRC']>();

  const {
    updateGuardian,
  } = useKatanga();

  const dispatch = useDispatch();
  const updateGuardianDetailsDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.updateGuardianDetailsDialogOpen,
  );

  useEffect(() => {
    setDialogOpen(updateGuardianDetailsDialogOpen);
  }, [updateGuardianDetailsDialogOpen]);

  useEffect(() => {
    if (!student) {
      setSubmitDisabled(true);
    }
    setSubmitDisabled(false);
  }, [student]);

  function handleSubmitUpdateGuardian() {
    if (student?.guardian?.guardianId){
      updateGuardian({
        guardianId: student.guardian.guardianId,
        guardianFirstName: firstName,
        guardianNRC: guardianNrc,
        guardianLastName: lastName,
        phone1: guardianPhone1,
        phone2: guardianPhone2,
        email: guardianEmail,
        studentId: student.studentId,
      });
    }
    dispatch(closeUpdateGuardianDetailsDialog());
  }

  return (
    <CoreDialog
      modalTitle="Update Guardian Information"
      submitAction={() => handleSubmitUpdateGuardian()}
      onClose={() => {
        dispatch(closeUpdateGuardianDetailsDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Guardian First Name"
              name="guardian first name "
              id="guardian-first-name-tf"
              value={firstName}
              onChange={(event) => setGuardianFirstName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Guardian Last Name"
              name="guardian last name "
              id="guardian-last-name-tf"
              value={lastName}
              onChange={(event) => setGuardianLastName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Primary Number"
              placeholder="Primary Number"
              name="phone 1"
              id="phone-1-tf"
              value={guardianPhone1}
              onChange={(event) => setPhone1(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Secondary Number"
              placeholder="Secondary Number"
              name="phone 2"
              id="phone-2-tf"
              value={guardianPhone2}
              onChange={(event) => setPhone2(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Guardian NRC"
              placeholder="Guardian NRC"
              name="guardian nrc"
              id="guardian-nrc-tf"
              value={guardianNrc}
              onChange={(event) => setGuardianNRC(event.target.value)}
            />
          </Grid>
          {!(student?.guardian?.contact?.email) && (
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                variant="outlined"
                margin="normal"
                label="Email"
                placeholder="email"
                name="email"
                id="email-tf"
                value={guardianEmail}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
          )}
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdateGuardianInformation({ student }: UpdateGuardianInformationProps) {
  return (
    <CoreDialogProvider>
      <Wrapped student={student} />
    </CoreDialogProvider>
  );
}
