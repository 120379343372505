/* eslint-disable react/jsx-props-no-spreading */
import {
  Autocomplete,
  Button,
  Container,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  closeSelectTermAndYearDialog, KatangaSlice, setTerm, setYear,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const terms = ['ONE', 'TWO', 'THREE'];
const currentYear = (new Date()).getFullYear();
const years = Array.from(new Array(5), (val, index) => `${(currentYear - index)}`);

function Wrapped() {
  const [errorMessage] = useState();
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [cookies, setCookie] = useCookies();

  const term = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.term,
  );

  const year = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.year,
  );

  const selectTermAndYearDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.selectTermAndYearDialogOpen,
  );

  const {
    dialogOpen,
    setDialogOpen,
  } = useCoreDialog();

  useEffect(() => {
    setDialogOpen(selectTermAndYearDialogOpen);
  }, [selectTermAndYearDialogOpen]);

  return (
    <CoreDialog
      modalTitle="Select Term & Year"
      submitAction={() => {
        dispatch(closeSelectTermAndYearDialog());
      }}
      onClose={() => dispatch(closeSelectTermAndYearDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={false}
    >
      <Container maxWidth="xs">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body2" color="error" align="center">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(e, value) => {
                dispatch(setTerm(value));
                setCookie('katangaSelectedTerm', value, { path: '/' });
              }}
              id="select-term-autocomplete"
              options={terms ?? []}
              value={term}
              disableClearable
              noOptionsText="No term options: contact admin"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Term"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(e, value) => {
                dispatch(setYear(value));
                setCookie('katangaSelectedYear', value, { path: '/' });
              }}
              id="select-year-autocomplete"
              options={years ?? []}
              value={year}
              disableClearable
              noOptionsText="No year options: contact admin"
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Year"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </Grid>
        </Grid>
      </Container>
    </CoreDialog>
  );
}

export function SelectTermAndYearDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}

export function SelectTermAndYear() {
  const [cookies, setCookie] = useCookies();
  const [selectTermOpen, setSelectTermOpen] = useState(false);
  const [selectYearOpen, setSelectYearOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  useEffect(() => {
    dispatch(setTerm(cookies.katangaSelectedTerm || 'ONE'));
    dispatch(setYear(cookies.katangaSelectedYear || `${(new Date()).getFullYear()}`));
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <div>
          <Button
            id="demo-positioned-button"
            aria-controls={selectTermOpen ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={selectTermOpen ? 'true' : undefined}
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setSelectTermOpen(true);
            }}
            endIcon={<ArrowDropDownIcon color="secondary" />}
          >
            {term}
          </Button>
          <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={selectTermOpen}
            onClose={() => {
              setAnchorEl(null);
              setSelectTermOpen(false);
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            elevation={1}
          >
            {terms?.map((t) => (
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                  setSelectTermOpen(false);
                  dispatch(setTerm(t));
                  setCookie('katangaSelectedTerm', t, { path: '/' });
                }}
                selected={term === t}
                key={t}
              >
                {t}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Grid>
      <Grid item>
        <Button
          id="demo-positioned-button"
          aria-controls={selectYearOpen ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={selectYearOpen ? 'true' : undefined}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setSelectYearOpen(true);
          }}
          endIcon={<ArrowDropDownIcon color="secondary" />}
        >
          {year}
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={selectYearOpen}
          onClose={() => {
            setAnchorEl(null);
            setSelectYearOpen(false);
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          elevation={1}
        >
          {years?.map((y) => (
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
                setSelectYearOpen(false);
                dispatch(setYear(y));
                setCookie('katangaSelectedYear', y, { path: '/' });
              }}
              selected={year === y}
              key={y}
            >
              {y}
            </MenuItem>
          ))}
        </Menu>
      </Grid>
    </Grid>
  );
}
