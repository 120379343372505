/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid,
  Paper,
  Switch,
  Typography,
} from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-context';
import React, {
  useState,
} from 'react';
import {
  Chart,
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Legend,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import {
  ArgumentScale, Stack, Animation,
} from '@devexpress/dx-react-chart';
import { scaleBand } from 'd3-scale';

function Root(props) {
  return (
    <Legend.Root
      {...props}
      className="m-auto flex-row"
      style={{
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
export function CapacityChart({ capacityReportData }) {
  const [pupils, setPupils] = useState(true);
  const {
    selectedYear,
    selectedTerm,
  } = useKatanga();
  if (!capacityReportData) return <div />;
  return (
    <Paper square style={{ padding: '2em' }}>
      {!pupils && (
        <Chart
          data={capacityReportData}
        >
          <Typography
            variant="body2"
            align="center"
            color="secondary"
            style={{ textTransform: 'capitalize' }}
          >
            {`Income Capacity Report For Term ${selectedTerm}, ${selectedYear}`.toLowerCase()}
          </Typography>
          <br />
          <ArgumentScale factory={scaleBand} />
          <ArgumentAxis indentFromAxis={17} />
          <ValueAxis />
          <BarSeries
            name="Capacity"
            valueField="capacityAmount"
            argumentField="enrollmentClassName"
            color="#121b2a"
          />
          <BarSeries
            name="Enrolled"
            valueField="totalEnrollmentAmount"
            argumentField="enrollmentClassName"
            color="#354C61"
          />
          <BarSeries
            name="Paid"
            valueField="totalFeesPaid"
            argumentField="enrollmentClassName"
            color="#D8C095"
          />
          <Animation />
          <Legend position="right" rootComponent={Root} />
          <Stack />
        </Chart>
      )}
      {pupils && (
        <Chart
          data={capacityReportData}
        >
          <Typography
            variant="body2"
            align="center"
            color="secondary"
            style={{ textTransform: 'capitalize' }}
          >
            {`Pupil Capacity Report For Term ${selectedTerm}, ${selectedYear}`.toLowerCase()}
          </Typography>
          <br />
          <ArgumentAxis indentFromAxis={20} />
          <ValueAxis />
          <BarSeries
            name="Capacity"
            valueField="capacity"
            argumentField="enrollmentClassName"
            color="#354C61"
          />
          <BarSeries
            name="Enrolled"
            valueField="totalEnrollments"
            argumentField="enrollmentClassName"
            color="#D8C095"
          />
          <Animation />
          <Legend position="right" rootComponent={Root} />
          <Stack />
        </Chart>
      )}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <Typography>Pupils</Typography>
        </Grid>
        <Grid item>
          <Switch
            value={pupils}
            onChange={() => {
              setPupils(!pupils);
            }}
          />
        </Grid>
        <Grid item>
          <Typography>Amounts</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
