import {
  Typography,
  Card,
  CardContent,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import React from 'react';
import { NumberWithCommas } from '@think-zambia-foundation/utils';

export function CardTotal({
  label,
  value,
}: any) {
  return (
    <ListItemButton style={{ padding: '0.05em', margin: 0 }}>
      <ListItemText style={{ padding: '0.05em', margin: 0 }}>
        <Card square>
          <br />
          <CardContent>
            <Typography align="center" variant="body2" color="secondary">{label}</Typography>
            <Typography align="center" variant="h6">{NumberWithCommas({ value })}</Typography>
          </CardContent>
        </Card>
      </ListItemText>
    </ListItemButton>
  );
}
