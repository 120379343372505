/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga } from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { AddFeesDialogProps } from './AddFeesDialog.types';

const feeTypes = [
  'Library Charge',
  'Other',
  'PTA',
  'Pupil Identity Card',
];

function Wrapped({
  setAddFeesDialogOpen,
  addFeesDialogOpen,
  referenceId,
}: AddFeesDialogProps) {
  const [selectedFeeType, setSelectedFeeType] = useState<string>();
  const [feeDescription, setFeeDescription] = useState<string>();
  const [feeAmount, setFeeAmount] = useState<number>();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (referenceId
      && selectedFeeType
      && feeDescription
      && feeAmount) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [selectedFeeType, feeDescription, feeAmount]);

  useEffect(() => {
    setDialogOpen(addFeesDialogOpen);
  }, [addFeesDialogOpen]);

  const {
    addingFee,
    addFeeData,
    addFee,
  } = useKatanga();

  useEffect(() => {
    if (addFeeData?.addFee) {
      setAddFeesDialogOpen(false);
    }
  }, [addFeeData]);

  function handleSubmitAddFees() {
    if (feeAmount && feeDescription && selectedFeeType) {
      addFee({
        amount: feeAmount,
        description: feeDescription,
        feeType: selectedFeeType,
        referenceId,
      });
    }
  }

  return (
    <CoreDialog
      modalTitle="Add Fees"
      submitAction={() => handleSubmitAddFees()}
      onClose={() => {
        setAddFeesDialogOpen(false);
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={addingFee}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedFeeType(value)}
              id="select-fee-autocomplete"
              options={feeTypes}
              disableClearable
              value={selectedFeeType || undefined}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Select Fee Type" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Fee Description"
              name="Fee Description"
              id="Fee-description-tf"
              disabled={!selectedFeeType}
              onChange={(event) => setFeeDescription(event.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="number"
              id="fee-amount-tf"
              onChange={(event) => setFeeAmount(parseFloat(event.target.value))}
              variant="outlined"
              margin="normal"
              label="Fee Amount"
              name="Fee Amount"
              disabled={!selectedFeeType}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function AddFeesDialog({
  setAddFeesDialogOpen,
  addFeesDialogOpen,
  referenceId,
}: AddFeesDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped
        addFeesDialogOpen={addFeesDialogOpen}
        referenceId={referenceId}
        setAddFeesDialogOpen={setAddFeesDialogOpen}
      />
    </CoreDialogProvider>
  );
}
