/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useDispatch } from 'react-redux';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { useKatanga, openUpdateFeeDialog } from '@think-zambia-foundation/katanga-context';
import { EditTwoTone } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddFeesDialog, UpdateFeesDialog } from '../../components';
import { SchoolFeesCardProps } from './SchoolFeesCard.types';

export function SchoolFeesCard({ title, referenceId, fees }: SchoolFeesCardProps) {
  const [addFeesDialogOpen, setAddFeesDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const { removeFee, removingFee } = useKatanga();
  const [feeId, setFeeId] = useState<string>('');

  const handleAddFeeBySchool = () => {
    setAddFeesDialogOpen(true);
  };

  return (
    <>
      <CollapsibleCard
        title={title}
        actions={[
          {
            key: '5c6065fa26724d01b9ccf9bb98220149',
            callback: handleAddFeeBySchool,
            icon: <AddTwoToneIcon />,
          },
        ]}
        loading={removingFee}
      >
        <TableList
          data={fees}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
          elevation={0}
          tableMetadata={{
            href: '/fees',
            tableKey: 'feeId',
            onClickCell: () => { },
            secondaryActions: [{
              key: 'de90f14cf0478cfe36c02d6ddf616e20',
              callback: (fee) => {
                dispatch(openUpdateFeeDialog());
                setFeeId(fee.feeId);
              },
              icon: () => <EditTwoTone />,
              text: 'Update',
              disableCard: () => false,
            }, {
              key: '4fedc970f21d4a77af75a48f168b454f',
              callback: (fee) => {
                removeFee({ feeId: fee.feeId });
              },
              icon: () => <DeleteIcon />,
              text: 'Delete',
              disableCard: () => false,
            }],
            cells: [{
              key: 'feeId',
              header: 'feeId',
              variant: 'name',
              value(data) { return `${data.feeType} (${data.amount})`; },
            }],
          }}
        />
      </CollapsibleCard>
      <AddFeesDialog
        setAddFeesDialogOpen={setAddFeesDialogOpen}
        addFeesDialogOpen={addFeesDialogOpen}
        referenceId={referenceId}
      />
      <UpdateFeesDialog
        feeId={feeId}
      />
    </>
  );
}
