/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Grid, CardContent, Toolbar, Container, Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  SmartTypography, TableList, Loading, CollapsibleCard,
} from '@think-zambia-foundation/components';
import { KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import { useSelector } from 'react-redux';

// TODO: Depricate
function InvoiceFees({ fees }: any) {
  return (
    <CollapsibleCard title="Fees">
      <TableList
        data={fees}
        disableSearch
        tableMetadata={{
          tableKey: 'feeId',
          onClickCell: () => { },
          cells: [
            {
              key: 'feeType',
              header: 'Name',
              variant: 'name',
            }, {
              key: 'description',
              header: 'Description',
              variant: 'name',
            }, {
              key: 'amount',
              header: 'Amount',
              variant: 'name',
            }],
        }}
        elevation={0}
      />
    </CollapsibleCard>
  );
}

export function InvoiceDetails({ invoice }: any) {
  const [totalAmount, setTotalAmount] = useState();

  const term = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.term,
  );
  const year = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.year,
  );

  useEffect(() => {
    setTotalAmount((invoice && invoice.enrollment.fees)
      ? invoice.enrollment.fees.reduce((prev: any, current: any) => prev + current.amount, 0) : 0);
  }, [invoice]);
  return (
    <CollapsibleCard
      title="Invoice Details"
    >
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Container>
            <Grid container spacing={1} alignContent="center">
              <Grid item xs={12} sm={4}>
                <CardContent>
                  <Typography variant="h6">Student Information</Typography>
                  <SmartTypography
                    text={invoice.studentNumber}
                    variant="name"
                    caption="Student Number"
                  />
                  <SmartTypography
                    text={`${invoice.firstName} ${invoice.lastName}`}
                    variant="name"
                    caption="Student Name"
                  />
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardContent>
                  <Typography variant="h6">Guardian Information</Typography>
                  <SmartTypography
                    text={`${invoice.guardian.firstName} ${invoice.guardian.lastName}`}
                    variant="name"
                    caption="Guardian Name"
                  />
                  <SmartTypography
                    text={invoice.guardian.email}
                    variant="email"
                    caption="Email"
                  />
                  <SmartTypography
                    text={invoice.guardian.phone1}
                    variant="phone"
                    caption="Primary Phone Number"
                  />
                  <SmartTypography
                    text={invoice.guardian.phone2}
                    variant="phone"
                    caption="Secondary Phone Number"
                  />
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardContent>
                  <Typography variant="h6">Enrollment Information</Typography>
                  <Grid container>
                    <Grid item sm={6}>
                      <SmartTypography
                        text={term}
                        variant="name"
                        caption="Term"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <SmartTypography
                        text={year}
                        variant="name"
                        caption="Year"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <SmartTypography
                        text={invoice.enrollment.enrollmentClass.enrollmentGrade.enrollmentGradeType}
                        variant="name"
                        caption="Grade"
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <SmartTypography
                        text={invoice.enrollment.enrollmentClass.enrollmentClassName}
                        variant="name"
                        caption="Class"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SmartTypography
                        text={`ZMW ${totalAmount}`}
                        variant="name"
                        caption="Total Fees Due"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </CardContent>
    </CollapsibleCard>
  );
}

export function RenderInvoiceDetails({ invoice }: any) {
  if (!invoice) return <div />;
  return (
    <Grid
      container
      spacing={1}
    >
      <Grid item xs={12}>
        <InvoiceDetails invoice={invoice} />
      </Grid>
      <Grid item xs={12}>
        <InvoiceFees fees={invoice.enrollment.fees} />
      </Grid>
      <Grid item xs={12}>
        <Toolbar />
      </Grid>
    </Grid>
  );
}

export function InvoiceDetailsController() {
  const [student, setStudent] = useState<any>();
  const { studentId } = useParams();

  const {
    errorFetchingStudentById,
    fetchingStudentById,
    getStudentById,
    fetchStudentByIdData,
  } = useKatanga();

  const term = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.term,
  );
  const year = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.year,
  );

  useEffect(() => {
    if (studentId && term && year) {
      getStudentById({
        studentId,
        term,
        year,
      });
    }
  }, [studentId, term, year]);

  useEffect(() => {
    setStudent(undefined);
  }, [errorFetchingStudentById]);

  useEffect(() => {
    if (fetchStudentByIdData?.student?.enrollment) {
      setStudent(fetchStudentByIdData.student || undefined);
    }
  }, [fetchStudentByIdData]);

  if (fetchingStudentById) return <Loading />;
  if (!student) return <div />;
  return (
    <RenderInvoiceDetails invoice={student} />
  );
}
