/* eslint-disable react/jsx-props-no-spreading */
import { Grid, TextField, Autocomplete } from '@mui/material';
import {
  closeReportFilterDialog,
  KatangaReportSlice,
  SchoolType, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useDispatch, useSelector } from 'react-redux';

function Wrapped() {
  const [schools, setSchools] = useState<SchoolType[]>([]);
  const [selectedSchoolFilter, setSelectedSchoolFilter] = useState<SchoolType>();

  const {
    fetchCapacityReportData,
  } = useKatanga();

  const dispatch = useDispatch();
  const reportFilterDialogOpen = useSelector(
    (state: { katangaReport: KatangaReportSlice }) => state.katangaReport.reportFilterDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (selectedSchoolFilter) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [selectedSchoolFilter]);

  useEffect(() => {
    if (fetchCapacityReportData?.capacityReport) {
      const uniqueSchools:any = [];
      fetchCapacityReportData?.capacityReport.forEach((row) => {
        if (!uniqueSchools?.some((s:SchoolType) => s?.schoolId === row?.schoolId)) {
          uniqueSchools?.push({ schoolId: row?.schoolId, name: row?.school?.name });
        }
      });
      setSchools(uniqueSchools);
    }
  }, [fetchCapacityReportData]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setDialogOpen(reportFilterDialogOpen);
  }, [reportFilterDialogOpen]);

  return (
    <CoreDialog
      modalTitle="Filter Capacity Report"
      submitAction={() => {
        if (selectedSchoolFilter) {
          setSearchParams({ schoolId: selectedSchoolFilter?.schoolId });
          setSelectedSchoolFilter(undefined);
        }
        dispatch(closeReportFilterDialog());
      }}
      onClose={() => dispatch(closeReportFilterDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12}>
          <Autocomplete
            onChange={(event, value) => {
              setSelectedSchoolFilter(value || undefined);
            }}
            id="select-school-autocomplete"
            options={schools}
            getOptionLabel={(option) => (`${option?.name || ''}`)}
            noOptionsText="No school options: contact admin"
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select School"
                variant="outlined"
                margin="normal"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

export function CapacityReportFilterDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
