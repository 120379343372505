import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Loading, MobileTableList, TableList } from '@think-zambia-foundation/components';
import { useAuth, useCore } from '@think-zambia-foundation/context';
import { openCreateSchoolDialog, SchoolType, useKatanga } from '@think-zambia-foundation/katanga-context';
import { Hidden, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { CreateSchoolDialog } from '../../components';

export function SchoolList() {
  const [schools, setSchools] = useState<SchoolType[]>();
  const {
    errorFetchingSchools,
    fetchingSchools,
    fetchSchoolsData,
    getSchools,
    createSchoolData,
  } = useKatanga();

  const {
    archiveOrganizationData,
    archivingOrganization,
  } = useCore();

  const dispatch = useDispatch();

  useEffect(() => {
    if (fetchSchoolsData && fetchSchoolsData.schools) {
      setSchools(fetchSchoolsData.schools);
    } else {
      setSchools([]);
    }
    // eslint-disable-next-line
  }, [fetchSchoolsData, errorFetchingSchools]);

  useEffect(() => {
    getSchools();
    // eslint-disable-next-line
  }, [createSchoolData, archiveOrganizationData]);

  function fabActionCallBack() {
    dispatch(openCreateSchoolDialog());
  }

  const { hasPermissionToClaim } = useAuth();

  if (fetchingSchools) return <Loading fullHeight />;
  if (errorFetchingSchools) return <>Error Fetching Schools. Please Try Again</>;
  if (!hasPermissionToClaim('katangaSchoolAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <Hidden only="xs">
        {archivingOrganization && (<LinearProgress />)}
        <TableList
          data={schools || []}
          tableMetadata={{
            href: '/school',
            tableKey: 'schoolId',
            fabAction: {
              callback: fabActionCallBack,
              icon: <AddIcon />,
            },
            cells: [{
              key: 'schoolId',
              header: 'School ID',
              variant: 'id',
            }, {
              key: 'name',
              header: 'Name',
              variant: 'name',
            }],
          }}
        />
      </Hidden>
      <Hidden smUp>
        <MobileTableList
          data={schools || []}
          tableMetadata={{
            href: '/school',
            tableKey: 'schoolId',
            avatarSrc(data) { return `${data.imageUrl}}`; },
            avatarAlt(data) { return `${data.name} ${data.name}`; },
            primary(data: any) { return `${data.name}`; },
            secondary(data: any) { return `${data.schoolId}`; },
          }}
        />
      </Hidden>
      <CreateSchoolDialog />
    </>
  );
}
