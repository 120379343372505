import React, { useEffect } from 'react';
import {
  closeRegisterStudentDialog,
  KatangaSlice, KatangaStudentApplicationProvider, useKatanga, useKatangaStudentApplication,
} from '@think-zambia-foundation/katanga-context';
import { ApplicationProvider, useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import ApplicationForm from './ApplicationForm';

function Wrapped() {
  const {
    createApplicationData,
    creatingApplication,
    errorCreatingApplication,
  } = useKatangaStudentApplication();

  const dispatch = useDispatch();
  const registerStudentDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.registerStudentDialogOpen,
  );

  const {
    registerStudentData,
    getApplications,
  } = useKatanga();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (creatingApplication) {
      setSubmitDisabled(true);
    }
  }, [creatingApplication]);

  useEffect(() => {
    setDialogOpen(registerStudentDialogOpen);
    // eslint-disable-next-line
    }, [registerStudentDialogOpen,
  ]);

  // const sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

  useEffect(() => {
    if (registerStudentData) {
      dispatch(closeRegisterStudentDialog());
      getApplications();
    }
  }, [registerStudentData]);

  useEffect(() => {
    if (createApplicationData) {
      dispatch(closeRegisterStudentDialog());
      getApplications();
    }
  }, [createApplicationData]);

  return (
    <CoreDialog
      modalTitle="Register Student"
      onClose={() => dispatch(closeRegisterStudentDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      {creatingApplication && <Loading />}
      {errorCreatingApplication && <Typography variant="body1" color="error">{errorCreatingApplication.message}</Typography>}
      <ApplicationForm />
    </CoreDialog>
  );
}

export function StudentRegistrationDialog() {
  return (
    <CoreDialogProvider>
      <ApplicationProvider>
        <KatangaStudentApplicationProvider>
          <Wrapped />
        </KatangaStudentApplicationProvider>
      </ApplicationProvider>
    </CoreDialogProvider>
  );
}
