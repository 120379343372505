/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Chip,
  Fab,
  Grid,
  Stack as ChipStack,
  Grow,
} from '@mui/material';
import { Loading, TableList } from '@think-zambia-foundation/components';
import { CapacityReportType, openReportFilterDialog, useKatanga } from '@think-zambia-foundation/katanga-context';
import React, {
  useEffect, useState,
} from 'react';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { CapacityReportFilterDialog } from './CapacityReportFilterDialog';
import { CapacityChart } from './CapacityChart';
import { CardTotal } from '../../components';

function CapacityReportTable({ capacityReportData }: any) {
  const [data, setData] = useState();
  useEffect(() => {
    if (capacityReportData) {
      setData(capacityReportData);
    }
  }, [capacityReportData]);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();

  function handleClick(enrollmentClassId: any) {
    const enrollmentClass = capacityReportData?.find(
      (reportData: any) => reportData?.enrollmentClassId === enrollmentClassId,
    );
    if (enrollmentClass) {
      navigate(`/report/payment/?schoolId=${enrollmentClass.schoolId}&enrollmentGradeId=${enrollmentClass.enrollmentGradeId}&enrollmentClassId=${enrollmentClass.enrollmentClassId}`);
    }
  }

  if (!data) return <div />;
  return (
    <TableList
      hidePagination
      data={data}
      tableMetadata={{
        href: '/class',
        tableKey: 'enrollmentClassId',
        onClickCell: handleClick,
        cells: [{
          key: 'schoolId',
          header: 'School',
          value(d) { return `${d?.school?.name || ''}`; },
          variant: 'id',
        }, {
          key: 'grade',
          header: 'Grade',
          value(d) { return `${d?.enrollmentGrade?.enrollmentGradeType || ''}`; },
          variant: 'id',
        }, {
          key: 'name',
          header: 'Class',
          value(d) { return `${d?.enrollmentClassName || ''}`; },
          variant: 'id',
        }, {
          key: 'capacity',
          header: 'Capacity',
          value(d) { return `${d?.capacity || ''}`; },
          variant: 'name',
        }, {
          key: 'totalEnrollments',
          header: 'Enrolled',
          value(d) { return `${d?.totalEnrollments || ''}`; },
          variant: 'name',
        }, {
          key: 'capacityAmount',
          header: 'Cap. Amount',
          value(d) { return `${d?.capacityAmount || ''}`; },
          variant: 'name',
        }, {
          key: 'totalEnrollmentAmount',
          header: 'Enr. Amount',
          value(d) { return `${d?.totalEnrollmentAmount || ''}`; },
          variant: 'name',
        }, {
          key: 'totalFeesPaid',
          header: 'Paid Amount',
          value(d) { return `${d?.totalFeesPaid || ''}`; },
          variant: 'name',
        }],
      }}
    />
  );
}

export function CapacityReportSummary(
  { capacityReportData }: { capacityReportData: CapacityReportType[] },
) {
  function getTotalCapacity(capacityReport: CapacityReportType[]) {
    return capacityReport?.reduce((total, capacityReportItem) => total + (capacityReportItem?.capacity || 0), 0);
  }

  function getTotalCapacityAmount(capacityReport: CapacityReportType[]) {
    return capacityReport?.reduce((total, capacityReportItem) => total + (capacityReportItem?.capacityAmount || 0), 0);
  }

  function getTotalPaidAmount(capacityReport: CapacityReportType[]) {
    return capacityReport?.reduce((total, capacityReportItem) => total + (capacityReportItem?.totalFeesPaid || 0), 0);
  }

  function getTotalEnrolled(capacityReport: CapacityReportType[]) {
    return capacityReport?.reduce((total, capacityReportItem) => total + (capacityReportItem?.totalEnrollments || 0), 0);
  }

  function getTotalEnrolledAmount(capacityReport: CapacityReportType[]) {
    return capacityReport?.reduce((total, capacityReportItem) => total + (capacityReportItem?.totalEnrollmentAmount || 0), 0);
  }

  const totalCapacity = getTotalCapacity(capacityReportData || []);
  const totalCapacityAmount = getTotalCapacityAmount(capacityReportData || []);
  const totalPaidAmount = getTotalPaidAmount(capacityReportData || []);
  const totalEnrolled = getTotalEnrolled(capacityReportData || []);
  const totalEnrolledAmount = getTotalEnrolledAmount(capacityReportData || []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={4}>
        <CardTotal label="Total Capacity" value={`${totalCapacity}`} units="Pupils" />
      </Grid>
      <Grid item xs={4}>
        <CardTotal label="Total Capacity Amount" value={`${totalCapacityAmount}`} units="ZMW" />
      </Grid>
      <Grid item xs={4}>
        <CardTotal label="Total Paid Amount" value={`${totalPaidAmount}`} units="ZMW" />
      </Grid>
      <Grid item xs={4}>
        <CardTotal label="Total Enrolled" value={`${totalEnrolled}`} units="Pupils" />
      </Grid>
      <Grid item xs={4}>
        <CardTotal label="Total Enrolled Amount" value={`${totalEnrolledAmount}`} units="ZMW" />
      </Grid>
      <Grid item xs={4}>
        <CardTotal label="Total Outstanding Amount" value={`${totalEnrolledAmount - totalPaidAmount}`} units="ZMW" />
      </Grid>
    </Grid>
  );
}

export function CapacityReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const searchSchoolId = searchParams.get('schoolId');

  const {
    fetchingCapacityReport,
    fetchCapacityReportData,
  } = useKatanga();

  const filteredCapacityReportData = fetchCapacityReportData?.capacityReport
    ?.filter((data: CapacityReportType) => !searchSchoolId || data.schoolId === searchSchoolId)
    || [];

  if (fetchingCapacityReport) return <Loading />;
  if (!filteredCapacityReportData) return <div />;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        {searchSchoolId && (
        <Grid item xs={12}>
          <ChipStack direction="row" spacing={1}>
            <Grow in timeout={500}>
              <Chip
                variant="outlined"
                color="secondary"
                size="small"
                label={`School ID: ${searchSchoolId}`}
                onDelete={() => {
                  setSearchParams({});
                }}
              />
            </Grow>
          </ChipStack>
          <br />
        </Grid>
        )}
        <Grid item xs={12}>
          <CapacityReportSummary capacityReportData={filteredCapacityReportData} />
        </Grid>
        <Grid item xs={12}>
          <CapacityChart capacityReportData={filteredCapacityReportData} />
        </Grid>
        <Grid item xs={12}>
          <CapacityReportTable capacityReportData={filteredCapacityReportData} />
        </Grid>
      </Grid>
      <CapacityReportFilterDialog />
      <div style={{
        position: 'fixed',
        bottom: '6.25em',
        right: '6.25em',
      }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => {}}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => dispatch(openReportFilterDialog())}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
