/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface FilterStudentListSlice {
  status: 'ACTIVE' | 'PRE_REGISTERED' | 'SUSPENDED' | 'GRADUATED' | 'INACTIVE' | 'ARCHIVED',
  enrolled: boolean,
  notEnrolled: boolean,
}

/**
 * Filter Student List
 */
export const filterStudentListSlice = createSlice({
  name: 'filterStudentList',
  initialState: {
    status: 'ACTIVE',
    enrolled: false,
    notEnrolled: false,
  },
  reducers: {
    setStatus: (state, action: PayloadAction<string>) => { state.status = action.payload; },
    setFilterEnrolled: (state, action: PayloadAction<boolean>) => { state.enrolled = action.payload; },
    setFilterNotEnrolled: (state, action: PayloadAction<boolean>) => { state.notEnrolled = action.payload; },
  },
});

export const {
  setStatus,
  setFilterEnrolled,
  setFilterNotEnrolled,
} = filterStudentListSlice.actions;

export default filterStudentListSlice.reducer;
