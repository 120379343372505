import React, { useEffect, useState } from 'react';
import { TableList } from '@think-zambia-foundation/components';
import { useAuth } from '@think-zambia-foundation/context';
import { KatangaSlice, StudentType, useKatanga } from '@think-zambia-foundation/katanga-context';

import { useSelector } from 'react-redux';
import { Typography } from '@mui/material';

export function MyStudents() {
  const [students, setStudents] = useState<StudentType[]>();
  const {
    hasPermissionToClaim,
    currentUser,
  } = useAuth();

  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  const {
    errorFetchingStudentsByUid,
    fetchStudentsByUidData,
    getStudentsByUid,
  } = useKatanga();

  useEffect(() => {
    if (currentUser && hasPermissionToClaim('katangaStudent') && term && year) {
      getStudentsByUid({ uid: currentUser.uid, term, year });
    }
  }, [hasPermissionToClaim, currentUser, term, year]);

  useEffect(() => {
    if (fetchStudentsByUidData && fetchStudentsByUidData.studentByUID) {
      setStudents(fetchStudentsByUidData.studentByUID);
    } else {
      setStudents([]);
    }
    // eslint-disable-next-line
  }, [fetchStudentsByUidData, errorFetchingStudentsByUid]);

  if (errorFetchingStudentsByUid) return <>Error Fetching Students. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudent')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      {students && students.length > 0 && (
      <Typography variant="h4">
        My Students
      </Typography>
      )}
      <TableList
        data={students || []}
        hideCSVDownload
        hideHeader
        disableSearch
        hidePagination
        tableMetadata={{
          href: '/student',
          tableKey: 'studentId',
          cells: [
            {
              key: 'studentNumber',
              header: 'Student Number',
              variant: 'id',
            }, {
              key: 'studentName',
              header: 'Name',
              value(data) { return `${data.firstName} ${data.lastName}`; },
              variant: 'name',
            }, {
              key: 'guardianName',
              header: 'Guardian',
              value(data) { return `${data.guardian.firstName} ${data.guardian.lastName}`; },
              variant: 'name',
            }, {
              key: 'phone',
              header: 'Phone',
              value(data) { return `${data.guardian.phone1}`; },
              variant: 'phone',
            }, {
              key: 'email',
              header: 'Email',
              value(data) { return `${data.guardian.email}`; },
              variant: 'email',
            }],
        }}
      />
    </>
  );
}
