/* eslint-disable react/prop-types */
import React from 'react';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/components';
import {
  List, ListItemButton, ListItemText,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SubjectType } from '@think-zambia-foundation/katanga-context';
import { SelectSubjectProps } from './SelectSubject.types';

export function SelectSubject({ subjects }: SelectSubjectProps) {
  const [searchParams] = useSearchParams();
  const selectedSubjectId = searchParams.get('selectedSubjectId');

  const navigate = useNavigate();

  function handleSelectSubject(e:any, c:SubjectType) {
    if (!c.subjectId) return;
    e.preventDefault();
    navigate(`/?selectedSubjectId=${c.subjectId}`);
  }

  return (
    <CollapsibleCard
      title="Select Subjects"
    >
      {subjects && (
        <List dense>
          {subjects
            .map((subject) => (
              <React.Fragment key={subject.subjectId}>
                <ListItemButton
                  key={subject.subjectId}
                  selected={selectedSubjectId === `${subject.subjectId}`}
                  onClick={(e) => {
                    handleSelectSubject(e, subject);
                  }}
                >
                  <ListItemText>
                    <SmartTypography
                      text={subject.name.toLowerCase()}
                      caption={`${subject.grade ? subject.grade.school.name : ''} | ${subject.grade ? subject.grade.enrollmentGradeType : ''}`}
                      variant="name"
                    />
                  </ListItemText>
                </ListItemButton>
              </React.Fragment>
            ))}
        </List>
      )}
    </CollapsibleCard>
  );
}
