/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga, KatangaSlice, closeAddEnrollmentClassDialog } from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog, useCore } from '@think-zambia-foundation/context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const dispatch = useDispatch();
  const { schoolId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedGradeId = searchParams.get('selectedGradeId');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedStaffId, setSelectedStaffId] = useState<string>();
  const [enrollmentClassName, setEnrollmentClassName] = useState<string>();
  const [capacity, setCapacity] = useState<string>();
  const {
    successAddingEnrollmentClass,
    addEnrollmentClass,
    addingEnrollmentClass,
  } = useKatanga();

  const addEnrollmentClassDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.addEnrollmentClassDialogOpen,
  );

  const {
    errorFetchingStaffList,
    fetchingStaffList,
    getStaff,
    fetchStaffListData,
  } = useCore();

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (enrollmentClassName
      && capacity
      && selectedStaffId
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [enrollmentClassName, capacity, selectedStaffId]);

  useEffect(() => {
    setDialogOpen(addEnrollmentClassDialogOpen);
    // eslint-disable-next-line
  }, [addEnrollmentClassDialogOpen]);

  const handleSubmitAddEnrollmentClass = () => {
    if (schoolId
      && selectedGradeId
      && selectedStaffId
      && enrollmentClassName
      && capacity) {
      addEnrollmentClass({
        schoolId,
        gradeId: selectedGradeId,
        staffId: selectedStaffId,
        enrollmentClassName,
        capacity: parseInt(capacity, 10),
      });
    }
  };

  useEffect(() => {
    getStaff();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  useEffect(() => {
    if (successAddingEnrollmentClass) {
      dispatch(closeAddEnrollmentClassDialog());
    }
    // eslint-disable-next-line
  }, [successAddingEnrollmentClass]);

  if (fetchingStaffList) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Add Enrollment Class"
      submitAction={() => { handleSubmitAddEnrollmentClass(); }}
      onClose={() => dispatch(closeAddEnrollmentClassDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={addingEnrollmentClass || fetchingStaffList}
    >
      <Container maxWidth="md">
        {errorMessage && (
          <Typography variant="body2" color="error">
            {errorMessage}
          </Typography>
        )}
        <FormControl>
          <Container component="main" maxWidth="xs">
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="add-enrollment-class-name"
              label="Enrollment Class Name"
              name="Enrollment Class Name"
              autoFocus
              onChange={(e) => setEnrollmentClassName(e.target.value)}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              id="add-enrollment-class-capacity"
              label="Capacity"
              name="Capacity"
              autoFocus
              margin="normal"
              onChange={(e) => setCapacity(e.target.value)}
              InputProps={{ type: 'number' }}
            />
            <Autocomplete
              onChange={(event, value) => (value && value ? setSelectedStaffId(value.staffId) : setSelectedStaffId(undefined))}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              fullWidth
              renderInput={(params) => <TextField {...params} margin="normal" label="Select Teacher" variant="outlined" />}
            />
          </Container>
        </FormControl>
      </Container>
    </CoreDialog>
  );
}

/**
 * Add Enrollment Class Dialog
 */
export function AddEnrollmentClassDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
