/* eslint-disable react/prop-types */
import { CollapsibleCard, Loading, SmartTypography } from '@think-zambia-foundation/components';
import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import { StudentType, useKatanga } from '@think-zambia-foundation/katanga-context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectStudentProps } from './SelectStudent.types';

export function SelectStudent({ students }: SelectStudentProps) {
  const [searchParams] = useSearchParams();
  const selectedSubjectId = searchParams.get('selectedSubjectId');
  const selectedClassId = searchParams.get('selectedClassId');
  const selectedStudentId = searchParams.get('selectedStudentId');

  const navigate = useNavigate();

  function handleSelectStudent(e: any, student: StudentType) {
    if (!student?.studentId) return;
    e.preventDefault();
    navigate(`/?${selectedSubjectId ? `selectedSubjectId=${selectedSubjectId}` : `selectedClassId=${selectedClassId}`}&selectedStudentId=${student.studentId}`);
  }

  const {
    fetchingEnrollmentSubjectsByEnrollmentSubjectId,
    fetchingStudentsByEnrollmentClassId,
  } = useKatanga();

  if (fetchingStudentsByEnrollmentClassId || fetchingEnrollmentSubjectsByEnrollmentSubjectId) {
    return <Loading />;
  }
  if (!students) return <div />;
  return (
    <CollapsibleCard
      title="Select Student"
    >
      {students && (
        <List dense>
          {students && students
            .map((student) => (
              <React.Fragment key={student?.studentId}>
                <ListItem
                  button
                  key={student?.studentId}
                  selected={selectedStudentId === `${student?.studentId}`}
                  onClick={(e) => {
                    handleSelectStudent(e, student);
                  }}
                >
                  <ListItemText>
                    <SmartTypography
                      text={`${student?.firstName} ${student?.lastName}`}
                      caption={`${student?.studentNumber}`}
                      variant="name"
                    />
                  </ListItemText>
                </ListItem>
              </React.Fragment>
            ))}
        </List>
      )}
    </CollapsibleCard>
  );
}
