import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { Loading, SmartTypography, CollapsibleCard } from '@think-zambia-foundation/components';
import {
  EnrollmentClassType,
  EnrollmentGradeType,
  openAddEnrollmentClassDialog,
  openChangeTuitionDialog,
  SchoolType,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SchoolFeesCard } from './SchoolFeesCard';
import { SubjectsCard } from './SubjectsCard';
import { ScholarshipCard } from './ScholarshipCard';
import { GradeInformationCardProps } from './GradeInformationCard.types';

export function GradeInformationCard({ school }: GradeInformationCardProps) {
  const { schoolId } = useParams();
  const [searchParams] = useSearchParams();
  const selectedGradeId = searchParams.get('selectedGradeId');
  const selectedClassId = searchParams.get('selectedClassId');

  const {
    addingEnrollmentClass,
  } = useKatanga();

  const dispatch = useDispatch();

  const handleClickAddEnrollmentClass = () => {
    dispatch(openAddEnrollmentClassDialog());
  };

  const handleClickChangeTuition = () => {
    dispatch(openChangeTuitionDialog());
  };

  const navigate = useNavigate();
  function handleSelectEnrollmentClass(e: any, _class: EnrollmentClassType) {
    if (!_class.enrollmentClassId) return;
    e.preventDefault();
    navigate(`/school/${schoolId}/?selectedGradeId=${selectedGradeId}&selectedClassId=${_class.enrollmentClassId}`);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_classes, setClasses] = useState<EnrollmentClassType[]>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_school, setSchool] = useState<SchoolType>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_grade, setGrade] = useState<EnrollmentGradeType>();
  useEffect(() => {
    if (school) {
      setSchool(school);
      const selectedGrade = school?.enrollmentGrades?.find(
        (grade: EnrollmentGradeType) => grade.enrollmentGradeId === selectedGradeId,
      );
      setGrade(selectedGrade);
      if (selectedGrade) {
        setClasses(selectedGrade.enrollmentClasses);
      } else {
        setClasses(undefined);
      }
    }
  }, [school, selectedGradeId]);

  if (!_school || !_grade) return <div />;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      <Grid item xs={12}>
        <CollapsibleCard
          title="Grade Information"
          actions={[
            {
              key: '215ba303e5ac46ff84ecaaa39f0d17a4',
              callback: handleClickChangeTuition,
              icon: <EditTwoToneIcon />,
            },
          ]}
        >
          <List dense>
            <ListItem>
              <ListItemText>
                <SmartTypography
                  caption="Grade"
                  text={_grade.enrollmentGradeType}
                  variant="name"
                />
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <SmartTypography
                  caption="Tuition"
                  text={`ZMW ${_grade.tuition}`}
                  variant="name"
                />
              </ListItemText>
            </ListItem>
          </List>
        </CollapsibleCard>
      </Grid>
      <Grid item xs={12}>
        <SchoolFeesCard title="Grade Fees" fees={_grade.fees} referenceId={_grade.enrollmentGradeId} />
      </Grid>
      <Grid item xs={12}>
        <ScholarshipCard
          scholarships={_grade.availableScholarships}
          title="Grade Scholarships"
          referenceId={_grade.enrollmentGradeId}
        />
      </Grid>
      <Grid item xs={12}>
        <SubjectsCard subjects={_grade.subjects} referenceId={_grade.enrollmentGradeId} title="Grade Subjects" />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleCard
          title="Classes"
          actions={[
            {
              key: '3914b29d77174ceda36641fad6076153',
              callback: handleClickAddEnrollmentClass,
              icon: <AddTwoToneIcon />,
            },
          ]}
        >
          {_classes && (
          <>
            {_classes.map((_class) => (
              <ListItemButton
                key={_class.enrollmentClassId}
                onClick={(e) => {
                  handleSelectEnrollmentClass(e, _class);
                }}
                selected={selectedClassId === _class.enrollmentClassId}
              >
                <ListItemText>
                  <SmartTypography
                    text={_class.enrollmentClassName}
                    caption={_class.staff ? `Teacher: ${_class.staff.firstName} ${_class.staff.lastName}` : ''}
                    variant="name"
                  />
                </ListItemText>
              </ListItemButton>
            ))}
            {addingEnrollmentClass && (
            <ListItem key="loading">
              <Loading />
            </ListItem>
            )}
          </>
          )}
        </CollapsibleCard>
      </Grid>
    </Grid>
  );
}
