import { GET_SCHOOL_BY_ID, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useAuth } from '@think-zambia-foundation/context';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { Loading } from '@think-zambia-foundation/components';
import {
  ChangeTuitionDialog,
  AddEnrollmentClassDialog,
  CreateSchoolDialog,
  AddSubjectDialog,
  CreateScholarshipDialog,
  ScholarshipDetailsDialog,
} from '../../components';
import { SchoolInformationCard } from './SchoolInformationCard';
import { GradeInformationCard } from './GradeInformationCard';
import { ClassInformationCard } from './ClassInformationCard';

function LoadingComponent() {
  return (
    <div style={{
      position: 'absolute',
      left: '50%',
      top: '50%',
    }}
    >
      <Loading />
    </div>
  );
}

export function SchoolDetails() {
  const { schoolId } = useParams();

  const {
    successAddingEnrollmentClass,
    addSubjectData,
    successUpdatingEnrollmentGrade,
    addFeeData,
    createScholarshipData,
    updateScholarshipData,
    katangaClient,
    removeScholarshipData,
    removeFeeData,
    successUpdatingEnrollmentClass,
    updateSubjectData,
    archiveSubjectData,
  } = useKatanga();

  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  const {
    data: fetchSchoolData,
    loading: fetchingSchoolData,
    refetch,
  } = useQuery(GET_SCHOOL_BY_ID, {
    client: katangaClient,
    variables: {
      schoolId,
      term,
      year,
    },
    pollInterval: 0,
  });

  useEffect(() => {
    if (schoolId) {
      refetch();
    }
  }, [
    schoolId,
    addFeeData,
    successAddingEnrollmentClass,
    addSubjectData,
    successUpdatingEnrollmentGrade,
    createScholarshipData,
    updateScholarshipData,
    removeScholarshipData,
    removeFeeData,
    successUpdatingEnrollmentClass,
    updateSubjectData,
    archiveSubjectData,
  ]);

  const { hasPermissionToClaim } = useAuth();
  if (!hasPermissionToClaim('katangaSchoolAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  if (fetchingSchoolData) return <LoadingComponent />;
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item sm={4} xs={12}>
          <SchoolInformationCard school={fetchSchoolData?.school} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <GradeInformationCard school={fetchSchoolData?.school} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <ClassInformationCard school={fetchSchoolData?.school} />
        </Grid>
      </Grid>
      <AddEnrollmentClassDialog />
      <AddSubjectDialog />
      <ChangeTuitionDialog />
      <CreateSchoolDialog />
      <CreateScholarshipDialog />
      <ScholarshipDetailsDialog />
    </>
  );
}
