/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import {
  Chip,
  Fab,
  Grid,
  Grow,
  Hidden,
  Stack,
} from '@mui/material';
import { Loading, TableList } from '@think-zambia-foundation/components';
import { PaymentReportType, useKatanga } from '@think-zambia-foundation/katanga-context';
import React, {
  useEffect, useState,
} from 'react';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import PrintIcon from '@mui/icons-material/Print';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useSearchParams } from 'react-router-dom';
import { useCore } from '@think-zambia-foundation/context';
import { PaymentReportProvider, usePaymentReport } from './PaymentReportContext';
import { PaymentReportFilterDialog } from './PaymentReportFilterDialog';
import { PaymentChart } from './PaymentChart';
import { CardTotal } from '../../components';

export function PaymentReportTable(
  { paymentReportData }: { paymentReportData: PaymentReportType[] },
) {
  if (!paymentReportData) return <div />;
  return (
    <TableList
      hidePagination
      data={paymentReportData || []}
      tableMetadata={{
        href: '/student',
        tableKey: 'studentId',
        cells: [{
          key: 'studentName',
          header: 'Student',
          variant: 'id',
        }, {
          key: 'enrollmentClassName',
          header: 'Class',
          variant: 'id',
        }, {
          key: 'enrollmentDate',
          header: 'Enrollment Date',
          value(d) { return `${d.enrollmentDate}`; },
          variant: 'name',
        },
        {
          key: 'totalScholarshipsApplied',
          header: 'Scholarships',
          value(d) { return `${d.totalScholarshipsApplied ? d.totalScholarshipsApplied : 0}`; },
          variant: 'name',
        }, {
          key: 'totalAmountDue',
          header: 'Total Due',
          variant: 'name',
        }, {
          key: 'totalFeesPaid',
          header: 'Total Paid',
          variant: 'name',
        }, {
          key: 'totalBalance',
          header: 'Balance',
          value(d) { return `${d.totalAmountDue - d.totalFeesPaid}`; },
          variant: 'name',
        }],
      }}
    />
  );
}

export function PaymentReportSummary(
  { paymentReportData }: {
    paymentReportData: PaymentReportType[]
  },
) {
  function getTotalAmountDue(paymentReport: PaymentReportType[]) {
    return paymentReport.reduce((total, paymentReportItem) => total + paymentReportItem.totalAmountDue, 0);
  }

  function getTotalAmountPaid(paymentReport: PaymentReportType[]) {
    return paymentReport.reduce((total, paymentReportItem) => total + paymentReportItem.totalFeesPaid, 0);
  }

  function getTotalScholarshipsApplied(paymentReport: PaymentReportType[]) {
    return paymentReport.reduce((total, paymentReportItem) => total + paymentReportItem.totalScholarshipsApplied, 0);
  }

  const totalAmountDue = getTotalAmountDue(paymentReportData || []);
  const totalAmountPaid = getTotalAmountPaid(paymentReportData || []);
  const totalScholarshipsApplied = getTotalScholarshipsApplied(paymentReportData || []);

  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="center">
      <Grid item sm={6} xs={12}>
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={6}>
            <CardTotal label="Total Due" value={`${totalAmountDue}`} units="ZMW" />
          </Grid>
          <Grid item xs={6}>
            <CardTotal label="Total Balance" value={`${totalAmountDue - totalAmountPaid}`} units="ZMW" />
          </Grid>
          <Grid item xs={6}>
            <CardTotal label="Total Paid" value={`${totalAmountPaid}`} units="ZMW" />
          </Grid>
          <Grid item xs={6}>
            <CardTotal label="Total Applied Scholarships" value={`${totalScholarshipsApplied}`} units="ZMW" />
          </Grid>
          <Grid item xs={6} />
        </Grid>
      </Grid>
      <Hidden only="xs">
        <Grid item sm={5}>
          <PaymentChart
            totalAmountPaid={totalAmountPaid}
            balance={totalAmountDue - totalAmountPaid}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

function FilteredPaymentReport() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchEnrollmentClassId, setSearchEnrollmentClassId] = useState<string>();

  useEffect(() => {
    setSearchEnrollmentClassId(searchParams.get('enrollmentClassId') || undefined);
  }, [searchParams]);

  const {
    fetchingPaymentReport,
    fetchPaymentReportData,
    revokeAppliedScholarshipData,
  } = useKatanga();

  const {
    paymentReportData,
    filteredPaymentReportData,
    setPaymentReportData,
    setPaymentReportFilterDialogOpen,
    setFilteredPaymentReportData,
  } = usePaymentReport();

  const { addPaymentData } = useCore();

  useEffect(() => {
    if (paymentReportData) {
      setFilteredPaymentReportData(
        paymentReportData.filter(
          (data) => !searchEnrollmentClassId
            || data.enrollmentClassId === searchEnrollmentClassId,
        ),
      );
    } else {
      setFilteredPaymentReportData(undefined);
    }
  }, [paymentReportData, searchEnrollmentClassId]);

  useEffect(() => {
    if ((fetchPaymentReportData?.paymentReport) || addPaymentData || revokeAppliedScholarshipData) {
      setPaymentReportData(fetchPaymentReportData.paymentReport);
    }
  }, [fetchPaymentReportData, addPaymentData, revokeAppliedScholarshipData]);

  if (fetchingPaymentReport) <Loading />;
  if (!filteredPaymentReportData) return <div />;
  return (
    <>
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={12}>
          <PaymentReportSummary paymentReportData={filteredPaymentReportData} />
        </Grid>
        {searchEnrollmentClassId && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            <Grow in timeout={500}>
              <Chip
                variant="outlined"
                color="secondary"
                size="small"
                label={`Class ID: ${searchEnrollmentClassId}`}
                onDelete={() => {
                  setSearchParams({});
                }}
              />
            </Grow>
          </Stack>
          <br />
        </Grid>
        )}
        <Grid item xs={12}>
          <PaymentReportTable paymentReportData={filteredPaymentReportData} />
        </Grid>
      </Grid>
      <div style={{
        position: 'fixed',
        bottom: '6.25em',
        right: '6.25em',
      }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => { }}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => setPaymentReportFilterDialogOpen(true)}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export function PaymentReport() {
  return (
    <PaymentReportProvider>
      <FilteredPaymentReport />
      <PaymentReportFilterDialog />
    </PaymentReportProvider>
  );
}
