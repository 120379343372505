/* eslint-disable react/prop-types */
import {
  Chip, Container, Divider, Fab, Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useRef } from 'react';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  SmartTypography, TableList,
} from '@think-zambia-foundation/components';
import { useReactToPrint } from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import {
  openFilterEnrollmentReportDialog, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { useDispatch } from 'react-redux';
import { EnrollmentReportFilterDialog } from './EnrollmentReportDialog';

import { EnrollmentReportFilterProvider, useEnrollmentReportFilter } from './EnrollmentReportContext';
import { EnrollmentReportTableProps } from './EnrollmentReport.types';

const PREFIX = 'EnrollmentReport';

const classes = {
  fab: `${PREFIX}-fab`,
};

const StyledEnrollmentReportFilterProvider = styled(EnrollmentReportFilterProvider)((
  {
    theme,
  },
) => ({
  [`& .${classes.fab}`]: {
    position: 'fixed',
    bottom: theme.spacing(5),
    right: theme.spacing(5),
  },
}));

function EnrollmentReportTable({ enrollments }: EnrollmentReportTableProps) {
  if (!enrollments) return <div />;
  return (
    <TableList
      data={enrollments}
      hidePagination
      tableMetadata={{
        href: '/student',
        tableKey: 'student.studentId',
        cells: [{
          key: 'name',
          header: 'Name',
          value(data) { return `${data.student.firstName} ${data.student.lastName}`; },
          variant: 'name',
        }, {
          key: 'grade',
          header: 'Grade',
          value(data) { return `${data.enrollmentClass.enrollmentGrade.enrollmentGradeType}`; },
          variant: 'name',
        }, {
          key: 'class',
          header: 'Class',
          value(data) { return `${data.enrollmentClass.enrollmentClassName}`; },
          variant: 'name',
        }, {
          key: 'year',
          header: 'Year',
          variant: 'name',
        }, {
          key: 'term',
          header: 'Term',
          variant: 'name',
        }, {
          key: 'enrollmentDate',
          header: 'Enrollment Date',
          variant: 'name',
        }],
      }}
    />
  );
}

function FilteredEnrollmentReport() {
  const {
    schoolFilter,
    setSchoolFilter,
  } = useEnrollmentReportFilter();

  const {
    fetchSchoolsData,
  } = useKatanga();

  if (!fetchSchoolsData?.schools) {
    return <div />;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12}>
        {schoolFilter
        && (
        <Chip
          size="small"
          label={schoolFilter.name}
          onDelete={() => setSchoolFilter(undefined)}
          color="secondary"
          style={{ marginBottom: '2em' }}
        />
        )}
      </Grid>
      <Grid item xs={12}>
        {fetchSchoolsData?.schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              <Typography variant="h6" color="textSecondary">
                {`${school.name}`}
              </Typography>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                  <React.Fragment key={enrollmentClass.enrollmentClassId}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        { enrollmentClass.staff && (
                        <SmartTypography
                          text={`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                          caption="Teacher"
                        />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <EnrollmentReportTable enrollments={enrollmentClass.enrollments} />
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

function PrintableEnrollmentReport() {
  const {
    fetchSchoolsData,
  } = useKatanga();

  const {
    schoolFilter,
  } = useEnrollmentReportFilter();

  if (!fetchSchoolsData?.schools) {
    return <div />;
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Grid item xs={12}>
        {fetchSchoolsData?.schools
          .filter((school) => !schoolFilter || school.schoolId === schoolFilter.schoolId)
          .map((school) => (
            <div key={school.schoolId} style={{ paddingTop: '2em', pageBreakAfter: 'always' }}>
              {school.enrollmentGrades && school.enrollmentGrades.map((enrollmentGrade) => (
                <React.Fragment key={enrollmentGrade.enrollmentGradeId}>
                  {enrollmentGrade.enrollmentClasses
                && enrollmentGrade.enrollmentClasses.map((enrollmentClass) => (
                  <div
                    key={enrollmentClass.enrollmentClassId}
                    style={{
                      padding: '2em',
                      pageBreakAfter: 'always',
                    }}
                  >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">School</Typography>
                        <Typography variant="h5" gutterBottom>
                          {`${school.name}`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="h6" color="textSecondary">Class</Typography>
                        <Typography variant="h5" gutterBottom>
                          {enrollmentClass.enrollmentClassName}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        { enrollmentClass.staff && (
                          <>
                            <Typography variant="h6" color="textSecondary">Teacher</Typography>
                            <Typography variant="h5" gutterBottom>
                              {`${enrollmentClass.staff.firstName} ${enrollmentClass.staff.lastName}`}
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Divider variant="inset" style={{ marginBottom: '1em' }} />
                        <Typography variant="h6" color="textSecondary" gutterBottom>Students</Typography>
                      </Grid>
                      {enrollmentClass.enrollments
                      && enrollmentClass.enrollments.map((enrollment) => (
                        <Grid key={enrollment.enrollmentId} item xs={12}>
                          <Typography variant="h6">
                            {`${enrollment.student.firstName} ${enrollment.student.lastName}`}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                ))}
                </React.Fragment>
              ))}
            </div>
          ))}
      </Grid>
    </Grid>
  );
}

export class ComponentToPrint extends React.PureComponent {
  render() {
    return (
      <Container>
        <PrintableEnrollmentReport />
      </Container>
    );
  }
}

export function EnrollmentReport() {
  const dispatch = useDispatch();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current!,
  });

  return (
    <StyledEnrollmentReportFilterProvider>
      <div style={{ display: 'none' }}><ComponentToPrint ref={componentRef} /></div>
      <FilteredEnrollmentReport />
      <EnrollmentReportFilterDialog />
      <div className={classes.fab}>
        <Grid container spacing={1}>
          <Grid item>
            <Fab
              color="secondary"
              onClick={handlePrint}
            >
              <PrintIcon />
            </Fab>
          </Grid>
          <Grid item>
            <Fab
              color="secondary"
              onClick={() => dispatch(openFilterEnrollmentReportDialog())}
            >
              <FilterListIcon />
            </Fab>
          </Grid>
        </Grid>
      </div>
    </StyledEnrollmentReportFilterProvider>
  );
}
