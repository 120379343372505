/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Container,
  FormControl, TextField, Typography,
} from '@mui/material';
import { closeCreateSchoolDialog, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [schoolName, setSchoolName] = useState<string>();
  const {
    createSchool,
    createSchoolData,
    creatingSchool,
    errorCreatingSchool,
  } = useKatanga();

  const dispatch = useDispatch();

  const createSchoolDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.createSchoolDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (schoolName) {
      setSubmitDisabled(false);
    }
    // eslint-disable-next-line
  }, [schoolName]);

  useEffect(() => {
    setDialogOpen(createSchoolDialogOpen);
    // eslint-disable-next-line
  }, [createSchoolDialogOpen]);

  const handleSubmitSchool = () => {
    createSchool({ schoolName });
  };

  useEffect(() => {
    if (errorCreatingSchool) {
      setErrorMessage('error creating school');
    }
  }, [errorCreatingSchool]);

  useEffect(() => {
    if (createSchoolData) {
      dispatch(closeCreateSchoolDialog());
    }
    // eslint-disable-next-line
  }, [createSchoolData]);

  return (
    <CoreDialog
      modalTitle="Create School"
      submitAction={() => handleSubmitSchool()}
      onClose={() => dispatch(closeCreateSchoolDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={creatingSchool}
    >
      {errorMessage && (
        <Typography variant="body2" color="error">
          {errorMessage}
        </Typography>
      )}
      <FormControl>
        <Container component="main" maxWidth="xs">
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="school-name"
            label="School Name"
            name="School Name"
            autoFocus
            onChange={(e) => setSchoolName(e.target.value)}
          />
        </Container>
      </FormControl>
    </CoreDialog>
  );
}

export function CreateSchoolDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
