/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Chart,
  PieSeries,
  Legend,
} from '@devexpress/dx-react-chart-bootstrap4';
import '@devexpress/dx-react-chart-bootstrap4/dist/dx-react-chart-bootstrap4.css';
import { Animation, Palette } from '@devexpress/dx-react-chart';

function Root(props) {
  return (
    <Legend.Root
      {...props}
      className="m-auto flex-row"
      style={{
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    />
  );
}

/* eslint-disable react/prop-types */
export function PaymentChart({ totalAmountPaid, balance }) {
  return (
    <div style={{ padding: '2em' }}>
      <Chart
        data={[{
          key: 'Paid',
          val: totalAmountPaid || 0,
        }, {
          key: 'Balance',
          val: balance || 0,
        }]}
      >
        <Palette scheme={['#354C61', '#D8C095']} />
        <PieSeries
          valueField="val"
          argumentField="key"
          innerRadius={0.9}
        />
        <Legend position="right" rootComponent={Root} />
        <Animation />
      </Chart>
    </div>
  );
}
