import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { MobileTableList, TableList } from '@think-zambia-foundation/components';
import { useAuth } from '@think-zambia-foundation/context';
import {
  ApplicationType,
  openRegisterStudentDialog,
  useKatanga,
  useKatangaStudentApplication,
  KatangaStudentApplicationProvider,
} from '@think-zambia-foundation/katanga-context';
import { Hidden, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import { StudentRegistrationDialog } from '../../components';

const PENDING_APPLICATION_STATUS = 'PENDING';

function Wrapped() {
  const [applications, setApplications] = useState<ApplicationType[]>();
  const { createApplicationData } = useKatangaStudentApplication();
  const {
    getApplications,
    fetchApplicationsData,
    fetchingApplications,
    errorFetchingApplications,
  } = useKatanga();

  const dispatch = useDispatch();

  useEffect(() => {
    if (createApplicationData?.createApplication) {
      getApplications();
    }
  }, [createApplicationData]);

  useEffect(() => {
    if (fetchApplicationsData?.applications) {
      setApplications(fetchApplicationsData.applications
        .filter(
          (application: ApplicationType) => application
            .applicationStatus === PENDING_APPLICATION_STATUS,
        ));
    } else {
      setApplications([]);
    }
  }, [fetchApplicationsData, errorFetchingApplications]);

  function fabActionCallBack() {
    dispatch(openRegisterStudentDialog());
  }

  const { hasPermissionToClaim } = useAuth();
  if (errorFetchingApplications) return <>Error Fetching Applications. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudentsAdmin')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <Hidden only="xs">
        {fetchingApplications && (<LinearProgress />)}
        <TableList
          data={applications || []}
          tableMetadata={{
            href: '/application',
            tableKey: 'applicationId',
            fabAction: {
              callback: fabActionCallBack,
              icon: <AddIcon />,
            },
            cells: [
              {
                key: 'applicationId',
                header: 'Application ID',
                variant: 'id',
              }, {
                key: 'studentId',
                header: 'Applicant Name',
                value(data) { return `${data.student.firstName} ${data.student.lastName}`; },
                variant: 'name',
              }, {
                key: 'schoolId',
                header: 'Applying To',
                value(data) { return `${data?.school?.name || ''}`; },
                variant: 'name',
              }, {
                key: 'gradeId',
                header: 'Grade',
                value(data) { return `${data?.grade?.enrollmentGradeType || ''}`; },
                variant: 'name',
              }, {
                key: 'applicationStatus',
                header: 'Status',
                variant: 'name',
              }],
          }}
        />
      </Hidden>
      <Hidden smUp>
        {fetchingApplications && (<LinearProgress />)}
        <MobileTableList
          data={applications || []}
          tableMetadata={{
            href: '/application',
            tableKey: 'applicationId',
            avatarSrc(data) { return `${data.student.imageUrl}}`; },
            avatarAlt(data) { return `${data.student.firstName} ${data.student.lastName}`; },
            primary(data: any) { return `${data.student.firstName} ${data.student.lastName}`; },
            secondary(data: any) { return `${data.applicationStatus}`; },
          }}
        />
      </Hidden>
      <StudentRegistrationDialog />
    </>
  );
}

export function ApplicationList() {
  return (
    <KatangaStudentApplicationProvider>
      <Wrapped />
    </KatangaStudentApplicationProvider>
  );
}
