/* eslint-disable react/jsx-props-no-spreading */
import { TextField, Autocomplete } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  closeFilterEnrollmentReportDialog, KatangaSlice, SchoolType, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialog, Loading } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useDispatch, useSelector } from 'react-redux';
import { SelectTermAndYear } from '../../components';
import { useEnrollmentReportFilter } from './EnrollmentReportContext';

function Wrapped() {
  const {
    fetchingSchools,
    getSchools,
    fetchSchoolsData,
  } = useKatanga();

  const [schools, setSchools] = useState<SchoolType[]>([]);

  const dispatch = useDispatch();
  const filterEnrollmentReportDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.filterEnrollmentReportDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  const {
    setSchoolFilter,
  } = useEnrollmentReportFilter();

  useEffect(() => {
    setDialogOpen(filterEnrollmentReportDialogOpen);
    setSubmitDisabled(false);
    getSchools();
    // eslint-disable-next-line
  }, [filterEnrollmentReportDialogOpen]);

  useEffect(() => {
    if (fetchSchoolsData) {
      setSchools(fetchSchoolsData.schools);
    }
  }, [fetchSchoolsData]);

  if (fetchingSchools) return <Loading />;
  return (
    <CoreDialog
      modalTitle="Filter Enrollment Report"
      submitAction={() => dispatch(closeFilterEnrollmentReportDialog())}
      onClose={() => dispatch(closeFilterEnrollmentReportDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
    >
      <Autocomplete
        onChange={(event, value) => setSchoolFilter(value || undefined)}
        id="select-school-autocomplete"
        options={schools}
        getOptionLabel={(option) => (`${option.name}`)}
        noOptionsText="No school options: contact admin"
        style={{ width: 300, marginBottom: '2em' }}
        renderInput={(params) => <TextField {...params} label="Select School" variant="outlined" />}
      />
      <SelectTermAndYear />
    </CoreDialog>
  );
}

export function EnrollmentReportFilterDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
