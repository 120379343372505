/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  FormControl, Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  closeAddSubjectDialog, KatangaSlice, setAddSubjectReferenceId, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog, useCore } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [subjectName, setSubjectName] = useState<string>();
  const [selectedStaffId, setSelectedStaffId] = useState<string>();

  const {
    addingSubject,
    addSubject,
    addSubjectData,
  } = useKatanga();

  const addSubjectDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.addSubjectDialogOpen,
  );
  const addSubjectReferenceId = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.addSubjectReferenceId,
  );

  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (addSubjectReferenceId
      && subjectName
      && selectedStaffId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [addSubjectReferenceId, subjectName, selectedStaffId]);

  useEffect(() => {
    setDialogOpen(addSubjectDialogOpen);
  }, [addSubjectDialogOpen]);

  const handleSubmitAddSubject = () => {
    if (subjectName && selectedStaffId) {
      addSubject({
        referenceId: addSubjectReferenceId,
        name: subjectName,
        staffId: selectedStaffId,
        term,
        year,
      }).then(() => {
        dispatch(setAddSubjectReferenceId(''));
      });
    }
  };

  const {
    errorFetchingStaffList,
    getStaff,
    fetchStaffListData,
  } = useCore();

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    if (addSubjectData) {
      dispatch(closeAddSubjectDialog());
    }
  }, [addSubjectData]);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  return (
    <CoreDialog
      modalTitle="Add Subject"
      submitAction={() => handleSubmitAddSubject()}
      onClose={() => {
        dispatch(closeAddSubjectDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={addingSubject}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Subject Name"
              id="subject name"
              onChange={(event) => setSubjectName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              onChange={(event, value) => setSelectedStaffId(value?.staffId)}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                    && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              renderInput={(params) => <TextField {...params} label="Select Teacher" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function AddSubjectDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
