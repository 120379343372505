import {
  Grid,
  ListItem,
  ListItemText,
} from '@mui/material';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/components';
import {
  EnrollmentClassType, EnrollmentGradeType, openUpdateEnrollmentClassDialog, SchoolType,
} from '@think-zambia-foundation/katanga-context';
import React, { useEffect, useState } from 'react';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SchoolFeesCard } from './SchoolFeesCard';
import { SubjectsCard } from './SubjectsCard';
import { UpdateEnrollmentClassDialog } from '../../components';
import { ScholarshipCard } from './ScholarshipCard';
import { ClassInformationCardProps } from './ClassInformationCard.types';

export function ClassInformationCard({ school }: ClassInformationCardProps) {
  const [searchParams] = useSearchParams();
  const selectedGradeId = searchParams.get('selectedGradeId');
  const selectedClassId = searchParams.get('selectedClassId');
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_school, setSchool] = useState<SchoolType>();
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [_class, setClass] = useState<EnrollmentClassType>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (school) {
      setSchool(school);
      const selectedGrade = school?.enrollmentGrades?.find(
        (grade:EnrollmentGradeType) => grade.enrollmentGradeId === selectedGradeId,
      );
      if (selectedGrade?.enrollmentClasses) {
        setClass(selectedGrade.enrollmentClasses
          .find((c:EnrollmentClassType) => c.enrollmentClassId === selectedClassId));
      } else {
        setClass(undefined);
      }
    }
  }, [school, selectedGradeId, selectedClassId]);

  if (!_school) return <div />;
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={1}
    >
      {_class && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="Class Information"
            actions={[
              {
                key: '0810e74255bd4f249d612f65b13f9c91',
                callback: () => dispatch(openUpdateEnrollmentClassDialog()),
                icon: <EditTwoToneIcon />,
              },
            ]}
          >
            {_class && (
              <>
                <ListItem>
                  <ListItemText>
                    <SmartTypography
                      caption="Class Name"
                      text={_class.enrollmentClassName}
                      variant="name"
                    />
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <SmartTypography
                      caption="Class Teacher"
                      text={_class.staff ? `${_class.staff.firstName} ${_class.staff.lastName}` : ''}
                      variant="name"
                    />
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <SmartTypography
                      caption="Capacity"
                      text={`${_class.capacity}`}
                      variant="name"
                    />
                  </ListItemText>
                </ListItem>
                <UpdateEnrollmentClassDialog enrollmentClassId={_class.enrollmentClassId} />
              </>
            )}
          </CollapsibleCard>
        </Grid>
      )}
      {_class && (
        <>
          <Grid item xs={12}>
            <SchoolFeesCard title="Class Fees" fees={_class.fees} referenceId={_class.enrollmentClassId} />
          </Grid>
          <Grid item xs={12}>
            <ScholarshipCard scholarships={_class.availableScholarships} title="Class Scholarships" referenceId={_class.enrollmentClassId} />
          </Grid>
        </>
      )}
      {_class && (
        <Grid item xs={12}>
          <SubjectsCard subjects={_class.subjects} referenceId={_class.enrollmentClassId} title="Class Subjects" />
        </Grid>
      )}
    </Grid>
  );
}
