import React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/components';
import { useAuth } from '@think-zambia-foundation/context';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useDispatch } from 'react-redux';
import { openCreateEnrollmentDialog, openUpdateEnrollmentDialog } from '@think-zambia-foundation/katanga-context';
import { CreateEnrollmentDialog } from '../CreateEnrollmentDialog';
import { UpdateEnrollmentDialog } from '../UpdateEnrollmentDialog';
import { StudentEnrollmentInfoProps } from './StudentEnrollmentInfo.types';

export function StudentEnrollmentInfo({ student }: StudentEnrollmentInfoProps) {
  const dispatch = useDispatch();
  const { hasPermissionToClaim } = useAuth();

  if (student?.studentStatus !== 'ACTIVE') return <div />;
  return (
    <CollapsibleCard
      title="Enrollment Information"
      actions={[
        {
          key: '9a84b895d53a4dc6b460a1cba7eab5a1',
          callback: () => dispatch(openCreateEnrollmentDialog()),
          icon: <AddTwoToneIcon />,
          disableCard: !(hasPermissionToClaim('katangaAdmin')) || !!(student?.enrollment),
          text: 'Create Enrollment',
        },
        {
          key: 'a807fbf592b94a8aa1752fdac54d378c',
          callback: () => dispatch(openUpdateEnrollmentDialog()),
          icon: <EditTwoToneIcon />,
          disableCard: !(hasPermissionToClaim('katangaAdmin')) || !(student?.enrollment),
          text: 'Edit Enrollment',
        },
      ]}
    >
      <CardContent>
        {(student && student.enrollment)
              && (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SmartTypography
                      caption="School"
                      text={`${student.enrollment.enrollmentClass.enrollmentGrade.school.name}`}
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <SmartTypography
                      caption="Grade"
                      text={`${student.enrollment.enrollmentClass.enrollmentGrade.enrollmentGradeType}`}
                      gutterBottom
                    />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <SmartTypography
                      caption="Class"
                      text={`${student.enrollment.enrollmentClass.enrollmentClassName}`}
                      gutterBottom
                    />
                  </Grid>
                  { student.enrollment.enrollmentClass.staff && (
                  <Grid item xs={6} sm={4}>
                    <SmartTypography
                      caption="Grade Teacher"
                      text={`${student.enrollment.enrollmentClass.staff.firstName} ${student.enrollment.enrollmentClass.staff.lastName}`}
                      variant="name"
                      gutterBottom
                    />
                  </Grid>
                  )}
                </Grid>
              )}
      </CardContent>
      <CreateEnrollmentDialog student={student} />
      { student && student.enrollment
      && <UpdateEnrollmentDialog enrollmentId={student.enrollment.enrollmentId} /> }
    </CollapsibleCard>
  );
}
