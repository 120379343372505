import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { CommonWrapper } from '@think-zambia-foundation/components';

function StudentRegistrationToolbar() {
  return (
    <CommonWrapper>
      <Toolbar style={{ backgroundColor: 'white' }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Students
          </Link>
          <Typography color="textPrimary">Student Registration</Typography>
        </Breadcrumbs>
      </Toolbar>
    </CommonWrapper>
  );
}

export default StudentRegistrationToolbar;
