/* eslint-disable react/prop-types */
import {
  Grid,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  EnrollmentSubjectType, KatangaSlice, StudentType, TeacherType, useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { StaffType, useAuth, useCore } from '@think-zambia-foundation/context';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SelectClass } from './SelectClass';
import { SelectSubject } from './SelectSubject';
import { SelectStudent } from './SelectStudent';
import { UpdateSubjectScore } from './UpdateSubjectScore';

export function TeacherDashboard() {
  const [searchParams] = useSearchParams();
  const selectedSubjectId = searchParams.get('selectedSubjectId');
  const selectedClassId = searchParams.get('selectedClassId');
  const selectedStudentId = searchParams.get('selectedStudentId');

  const [staff, setStaff] = useState<StaffType>();
  const [teacher, setTeacher] = useState<TeacherType>();
  const [students, setStudents] = useState<StudentType[]>();
  const [enrollmentSubjectsData, setEnrollmentSubjectsData] = useState<EnrollmentSubjectType[]>();
  const [enrollmentSubjects, setEnrollmentSubjects] = useState<EnrollmentSubjectType[]>();

  const {
    getStaffByUid,
    fetchStaffByUidData,
  } = useCore();

  const {
    fetchEnrollmentSubjectsByEnrollmentSubjectIdData,
    fetchStudentsByEnrollmentClassIdData,
    fetchTeacherByIdData,
    getEnrollmentSubjectsByEnrollmentSubjectId,
    getStudentById,
    getStudentsByEnrollmentClassId,
    getTeacher,
    fetchStudentByIdData,
    updateEnrollmentSubjectData,
  } = useKatanga();

  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  const { currentUser } = useAuth();
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      getStaffByUid({ uid: currentUser.uid });
    }
  }, [currentUser]);

  useEffect(() => {
    if (fetchStaffByUidData && fetchStaffByUidData.staffByUid) {
      setStaff(fetchStaffByUidData.staffByUid);
    } else {
      setStaff(undefined);
    }
  }, [fetchStaffByUidData]);

  useEffect(() => {
    if (staff) {
      getTeacher(staff);
    }
  }, [staff]);

  useEffect(() => {
    if (fetchTeacherByIdData?.teacher) {
      setTeacher(fetchTeacherByIdData.teacher);
    } else {
      setTeacher(undefined);
    }
  }, [fetchTeacherByIdData]);

  useEffect(() => {
    setStudents(undefined);
    setEnrollmentSubjects(undefined);
    if (selectedClassId) {
      getStudentsByEnrollmentClassId({
        term,
        year,
        enrollmentClassId: selectedClassId,
      });
    }
  }, [selectedClassId, term, year]);

  useEffect(() => {
    if (fetchStudentsByEnrollmentClassIdData?.studentsByEnrollmentClassId) {
      setStudents(fetchStudentsByEnrollmentClassIdData.studentsByEnrollmentClassId);
    }
  }, [fetchStudentsByEnrollmentClassIdData]);

  // Fetch students by enrollment subject id
  useEffect(() => {
    setStudents(undefined);
    setEnrollmentSubjects(undefined);
    if (selectedSubjectId) {
      getEnrollmentSubjectsByEnrollmentSubjectId({
        term,
        year,
        subjectId: selectedSubjectId,
      });
    }
  }, [selectedSubjectId, term, year]);

  useEffect(() => {
    if (fetchEnrollmentSubjectsByEnrollmentSubjectIdData?.enrollmentSubjectsByEnrollmentSubjectId) {
      setEnrollmentSubjectsData(fetchEnrollmentSubjectsByEnrollmentSubjectIdData
        .enrollmentSubjectsByEnrollmentSubjectId);
      setStudents(fetchEnrollmentSubjectsByEnrollmentSubjectIdData
        .enrollmentSubjectsByEnrollmentSubjectId
        .map((enrollmentSubject) => enrollmentSubject.enrollment.student));
    }
  }, [fetchEnrollmentSubjectsByEnrollmentSubjectIdData]);

  useEffect(() => {
    setEnrollmentSubjects(undefined);
    if (enrollmentSubjectsData && selectedSubjectId && selectedStudentId) {
      setEnrollmentSubjects(enrollmentSubjectsData
        .filter(
          (enrollmentSubject: EnrollmentSubjectType) => enrollmentSubject
            .enrollment.student.studentId === selectedStudentId,
        ));
    }
  }, [fetchStudentByIdData, selectedStudentId, enrollmentSubjectsData]);

  useEffect(() => {
    if (selectedStudentId) {
      getStudentById({
        studentId: selectedStudentId,
        term,
        year,
      });
    }
  }, [selectedStudentId]);

  useEffect(() => {
    if (fetchStudentByIdData?.student?.enrollment) {
      setEnrollmentSubjects(fetchStudentByIdData.student.enrollment.enrollmentSubjects);
    }
  }, [fetchStudentByIdData]);

  useEffect(() => {
    if (updateEnrollmentSubjectData?.updateEnrollmentSubject && selectedStudentId) {
      getStudentById({
        studentId: selectedStudentId,
        term,
        year,
      });
    }
  }, [updateEnrollmentSubjectData]);

  if (!staff) return <div />;
  if (!teacher) return <div />;
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectClass classes={teacher.classes} />
          </Grid>
          <Grid item xs={12}>
            <SelectSubject subjects={teacher.subjects} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <SelectStudent students={students} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <UpdateSubjectScore enrollmentSubjects={enrollmentSubjects} />
      </Grid>
    </Grid>
  );
}
