/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  TableListProvider, TableMetadataType, useAuth, useTableList,
} from '@think-zambia-foundation/context';
import { TableListBase, MobileTableListBase, Loading } from '@think-zambia-foundation/components';
import {
  openFilterStudentListDialog, StudentType, useKatanga, KatangaSlice,
} from '@think-zambia-foundation/katanga-context';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Hidden } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StudentListFilterDialog } from '../../components';
import { FilterStudentListSlice } from '../../reducer';

function QueryParameterProvider() {
  const { page, setPage } = useTableList();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const pageQuery = searchParams.get('page');
    if (pageQuery) {
      setPage(parseInt(pageQuery, 10));
    }
  }, []);

  useEffect(() => {
    setSearchParams({ page: `${page}` });
  }, [page]);

  return (
    <div />
  );
}

function StudentList() {
  const {
    errorFetchingStudents,
    fetchingStudents,
    fetchStudentsData,
    archiveStudentData,
    getStudents,
  } = useKatanga();

  const dispatch = useDispatch();

  const term = useSelector((state: { katanga: KatangaSlice }) => state.katanga.term);
  const year = useSelector((state: { katanga: KatangaSlice }) => state.katanga.year);

  useEffect(() => {
    if (archiveStudentData) {
      getStudents({ term, year });
    }
  }, [archiveStudentData]);

  const status = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.status,
  );
  const enrolled = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.enrolled,
  );

  const filterNotEnrolled = useSelector(
    (state: { filterStudentList: FilterStudentListSlice }) => state.filterStudentList.notEnrolled,
  );

  const filteredStudents = fetchStudentsData?.students.filter(
    (student) => student.studentStatus === status && (!enrolled || student.enrollment) && (!filterNotEnrolled || !student.enrollment),
  );

  const [desktopTableMetadata] = useState<TableMetadataType>({
    href: '/student',
    tableKey: 'studentId',
    fabAction: {
      callback: () => { dispatch(openFilterStudentListDialog()); },
      icon: <FilterListIcon />,
    },
    cells: [
      {
        key: 'studentNumber',
        header: 'Student Number',
        value(data: StudentType) { return `${data?.studentNumber}`; },
        variant: 'id',
      }, {
        key: 'studentName',
        header: 'Name',
        value(data: StudentType) { return `${data?.firstName} ${data?.lastName}`; },
        variant: 'name',
      }, {
        key: 'guardianName',
        header: 'Guardian',
        value(data: StudentType) { return `${data?.guardian?.firstName || ''} ${data?.guardian?.lastName || ''}`; },
        variant: 'name',
      }, {
        key: 'phone',
        header: 'Phone',
        value(data: StudentType) { return `${data?.contact?.phone1 || data?.guardian?.contact?.phone1 || ''}`; },
        variant: 'phone',
      }, {
        key: 'email',
        header: 'Email',
        value(data: StudentType) { return `${data?.contact?.email || data?.guardian?.contact?.email || ''}`; },
        variant: 'email',
      }],
  });

  const [mobileTableMetadata] = useState({
    href: '/student',
    tableKey: 'studentId',
    searchIcon: <FilterListIcon />,
    onClickSearchIcon: () => { dispatch(openFilterStudentListDialog()); },
    // avatarSrc(data: StudentType) { return `${data.imageUrl}}`; },
    avatarAlt(data: StudentType) { return `${data.firstName} ${data.lastName}`; },
    primary(data: StudentType) { return `${data.firstName} ${data.lastName}`; },
    secondary(data: StudentType) { return `${data.studentNumber}`; },
  });

  const { hasPermissionToClaim } = useAuth();
  if (fetchingStudents) return <Loading fullHeight />;
  if (errorFetchingStudents) return <>Error Fetching Students. Please Try Again</>;
  if (!hasPermissionToClaim('katangaStudentUser')) return <>You do not have permissions to this page. contact the system admin</>;
  return (
    <>
      <Hidden only="xs">
        <TableListProvider tableMetadata={desktopTableMetadata}>
          <QueryParameterProvider />
          <TableListBase
            data={filteredStudents}
            tableMetadata={desktopTableMetadata}
          />
        </TableListProvider>
      </Hidden>
      <Hidden smUp>
        <TableListProvider tableMetadata={mobileTableMetadata}>
          <QueryParameterProvider />
          <MobileTableListBase
            data={filteredStudents}
            tableMetadata={mobileTableMetadata}
          />
        </TableListProvider>
      </Hidden>
      <StudentListFilterDialog />
    </>
  );
}

export default StudentList;
