/* eslint-disable no-unused-vars */
import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Hidden from '@mui/material/Hidden';
import { StudentInformationRegistrationFormProps } from './StudentInformationRegistrationForm.types';

const PREFIX = 'StudentInformationRegistrationForm';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`& .${classes.root}`]: {
    minWidth: 275,
  },
});

export function StudentInformationRegistrationForm({
  newStudent,
  setNewStudentFirstName,
  setNewStudentMiddleName,
  setNewStudentLastName,
  setNewStudentGender,
  setNewStudentDateOfBirth,
}: StudentInformationRegistrationFormProps) {
  return (
    <Root>
      <Card className={classes.root} variant="outlined" square>
        <CardContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12}>
              <Typography color="textSecondary" gutterBottom>
                Student Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="first-name"
                label="First Name"
                value={newStudent.firstName}
                onChange={(e) => setNewStudentFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="middle-name"
                label="Middle Name"
                value={newStudent.middleName}
                onChange={(e) => setNewStudentMiddleName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                variant="outlined"
                fullWidth
                id="last-name"
                label="Last Name"
                value={newStudent.lastName}
                onChange={(e) => setNewStudentLastName(e.target.value)}
              />
            </Grid>
            <Hidden only="xs">
              <Grid item sm={3} />
            </Hidden>
            <Grid item xs={12} sm={3}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="DD-MMM-YYYY"
                  label="Date of birth"
                  value={newStudent.dateOfBirth}
                  onChange={(date: any) => {
                    if (date) {
                      setNewStudentDateOfBirth(date);
                    }
                  }}
                  renderInput={() => (
                    <TextField
                      label="Date"
                      helperText="Something"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Select
                label="gender"
                id="gender"
                defaultValue="Male"
                value={newStudent.gender}
                onChange={(e) => setNewStudentGender(e.target.value)}
                variant="outlined"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Root>
  );
}
