import {
  // SelectSchoolStep,
  StudentInformationStep,
  // GuardianInformationStep,
  ContactInformationStep,
  SelectSchoolStep,
  GuardianInformationStep,
} from '@think-zambia-foundation/katanga-components';
import { useKatangaStudentApplication, useKatanga } from '@think-zambia-foundation/katanga-context';
import { ApplicationStepper } from '@think-zambia-foundation/components';
import { useApplication } from '@think-zambia-foundation/context';
import React, { useEffect } from 'react';

function Wrapped() {
  const {
    fetchSchoolsData,
  } = useKatanga();
  const {
    addressLine1,
    addressLine2,
    applicationValidationSuccess,
    city,
    contactInfoValidationSuccess,
    country,
    creatingApplication,
    email,
    guardianFirstName,
    guardianId,
    // guardianInfoValidationSuccess,
    guardianLastName,
    phone1,
    phone2,
    postalCode,
    previousSchool,
    religion,
    selectedGender,
    selectedSchoolId,
    selectSchoolValidationSuccess,
    studentDateOfBirth,
    studentFirstName,
    studentInfoValidationSuccess,
    studentLastName,
    studentMiddleName,
    guardianPhone1,
    guardianPhone2,
    guardianEmail,
    guardianAddressLine1,
    guardianAddressLine2,
    guardianCity,
    guardianPostalCode,
    guardianProvince,
    guardianCountry,
    setAddressLine1,
    setAddressLine2,
    setCity,
    setCountry,
    setEmail,
    setGuardianFirstName,
    setGuardianId,
    setGuardianLastName,
    setPhone1,
    setPhone2,
    setPostalCode,
    setPreviousSchool,
    setReligion,
    setSelectedGender,
    setSelectedGradeId,
    setSelectedSchoolId,
    setStudentDateOfBirth,
    setStudentFirstName,
    setStudentLastName,
    setStudentMiddleName,
    submitApplication,
    setGuardianPhone1,
    setGuardianPhone2,
    setGuardianEmail,
    setGuardianAddressLine1,
    setGuardianAddressLine2,
    setGuardianCity,
    setGuardianPostalCode,
    setGuardianProvince,
    setGuardianCountry,
  } = useKatangaStudentApplication();

  const {
    activeStep,
    steps,
    handleBack,
    handleNext,
    setSteps,
  } = useApplication();

  useEffect(() => {
    setSteps([
      {
        title: 'Select School and Grade',
        step: <SelectSchoolStep
          schools={fetchSchoolsData?.schools}
          selectedSchoolId={selectedSchoolId}
          setSelectedGradeId={setSelectedGradeId}
          setSelectedSchoolId={setSelectedSchoolId}
        />,
        validation: selectSchoolValidationSuccess,
      },
      {
        title: 'Enter Student Information',
        step: <StudentInformationStep
          studentFirstName={studentFirstName}
          studentMiddleName={studentMiddleName}
          studentLastName={studentLastName}
          studentDateOfBirth={studentDateOfBirth}
          previousSchool={previousSchool}
          religion={religion}
          setStudentFirstName={setStudentFirstName}
          setStudentLastName={setStudentLastName}
          setStudentMiddleName={setStudentMiddleName}
          setStudentDateOfBirth={setStudentDateOfBirth}
          setSelectedGender={setSelectedGender}
          setPreviousSchool={setPreviousSchool}
          setReligion={setReligion}
        />,
        validation: studentInfoValidationSuccess,
      },
      {
        title: 'Enter Contact Information',
        step: <ContactInformationStep
          phone1={phone1}
          phone2={phone2}
          email={email}
          addressLine1={addressLine1}
          addressLine2={addressLine2}
          city={city}
          postalCode={postalCode}
          country={country}
          setPhone1={setPhone1}
          setPhone2={setPhone2}
          setEmail={setEmail}
          setAddressLine1={setAddressLine1}
          setAddressLine2={setAddressLine2}
          setCity={setCity}
          setPostalCode={setPostalCode}
          setCountry={setCountry}
        />,
        validation: contactInfoValidationSuccess,
      },
      {
        title: 'Enter Guardian Information (optional)',
        step: <GuardianInformationStep
          guardianId={guardianId}
          guardianFirstName={guardianFirstName}
          guardianLastName={guardianLastName}
          guardianPhone1={guardianPhone1}
          guardianPhone2={guardianPhone2}
          guardianEmail={guardianEmail}
          guardianAddressLine1={guardianAddressLine1}
          guardianAddressLine2={guardianAddressLine2}
          guardianCity={guardianCity}
          guardianPostalCode={guardianPostalCode}
          guardianProvince={guardianProvince}
          guardianCountry={guardianCountry}
          setGuardianId={setGuardianId}
          setGuardianFirstName={setGuardianFirstName}
          setGuardianLastName={setGuardianLastName}
          setGuardianPhone1={setGuardianPhone1}
          setGuardianPhone2={setGuardianPhone2}
          setGuardianEmail={setGuardianEmail}
          setGuardianAddressLine1={setGuardianAddressLine1}
          setGuardianAddressLine2={setGuardianAddressLine2}
          setGuardianCity={setGuardianCity}
          setGuardianPostalCode={setGuardianPostalCode}
          setGuardianProvince={setGuardianProvince}
          setGuardianCountry={setGuardianCountry}
        />,
        validation: true,
      },
    ]);
    // eslint-disable-next-line
  }, [selectSchoolValidationSuccess,
    applicationValidationSuccess,
    contactInfoValidationSuccess,
    guardianFirstName,
    guardianId,
    // guardianInfoValidationSuccess,
    guardianLastName,
    previousSchool,
    religion,
    selectedGender,
    studentDateOfBirth,
    studentFirstName,
    studentInfoValidationSuccess,
    studentLastName,
    studentMiddleName,
    phone1,
    phone2,
    email,
    addressLine1,
    addressLine2,
    city,
    postalCode,
    country,
  ]);

  function handleSubmitApplication() {
    submitApplication(undefined);
  }

  if (!steps) return <>No Steps</>;
  return (
    <ApplicationStepper
      handleSubmitApplication={() => handleSubmitApplication()}
      activeStep={activeStep}
      handleBack={handleBack}
      handleNext={handleNext}
      steps={steps}
      loading={creatingApplication}
    />
  );
}

function ApplicationForm() {
  return (
    <Wrapped />
  );
}
export default ApplicationForm;
