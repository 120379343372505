import { useAuth } from '@think-zambia-foundation/context';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function RouteDefaultPage() {
  const {
    authorized,
    loadingCurrentUser,
    hasPermissionToClaim,
  } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!loadingCurrentUser) {
      if (authorized && hasPermissionToClaim('9b9c7087f5a84bc196d4cef8d0560714') === false) {
        if (hasPermissionToClaim('katangaTeacher')) {
          navigate('/dashboard/teacher');
        } else if (hasPermissionToClaim('katangaStudent')) {
          navigate('/myStudents');
        } else {
          navigate('/student');
        }
      } else {
        navigate('/login');
      }
    }
  }, [authorized, hasPermissionToClaim]);

  return <div />;
}

export default RouteDefaultPage;
