import React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { SmartTypography, CollapsibleCard } from '@think-zambia-foundation/components';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useDispatch } from 'react-redux';
import { openUpdateGuardianDetailsDialog, useKatanga } from '@think-zambia-foundation/katanga-context';
import { UpdateGuardianInformation } from '../UpdateGuardianDialog';
import { GuardianContactInfoProps } from './GuardianContactInfo.types';

export function GuardianContactInfo({ student }: GuardianContactInfoProps) {
  const dispatch = useDispatch();
  const {
    updatingGuardian,
  } = useKatanga();

  if (!student?.guardian) return <div />;
  return (
    <>
      <CollapsibleCard
        title="Guardian Information"
        actions={[{
          key: '0764e3a4a3fb4e4087986182f0eab9d5',
          callback: () => dispatch(openUpdateGuardianDetailsDialog()),
          icon: <EditTwoToneIcon />,
        }]}
        loading={updatingGuardian}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SmartTypography
                caption="Guardian Name"
                text={`${student?.guardian?.firstName || ''} ${student?.guardian?.lastName || ''}`}
                gutterBottom
              />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography
                caption="Address"
                text={student?.guardian?.address
                  ? `${student?.guardian?.address?.addressLine1 || ''} ${student?.guardian?.address?.addressLine2 || ''}`
                  : ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <SmartTypography
                caption="Phone number"
                variant="phone"
                text={student?.guardian?.contact?.phone1 || ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <SmartTypography
                caption="Secondary number"
                variant="phone"
                text={student?.guardian?.contact?.phone2 || ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography
                caption="Email"
                text={student?.guardian?.contact?.email || ''}
                gutterBottom
                variant="email"
              />
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      <UpdateGuardianInformation student={student} />
    </>
  );
}
