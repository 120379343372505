/* eslint-disable react/prop-types */
import React from 'react';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { EditTwoTone } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { CollapsibleCard, TableList } from '@think-zambia-foundation/components';
import { useDispatch } from 'react-redux';
import {
  openAddSubjectDialog,
  openUpdateSubjectDialog,
  setAddSubjectReferenceId,
  setSubjectId,
  SubjectType,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { SubjectsCardProps } from './SubjectsCard.types';
import { UpdateSubjectDialog } from '../../components';

export function SubjectsCard({ subjects, referenceId, title }: SubjectsCardProps) {
  const dispatch = useDispatch();

  const { archiveSubject, archivingSubject, updatingSubject } = useKatanga();

  const handleClickAddSubject = () => {
    dispatch(setAddSubjectReferenceId(referenceId));
    dispatch(openAddSubjectDialog());
  };

  return (
    <>
      <CollapsibleCard
        title={title}
        actions={[
          {
            key: '8f18e6b72d2f4be59eddf3763bf45985',
            callback: handleClickAddSubject,
            icon: <AddTwoToneIcon />,
          },
        ]}
        loading={archivingSubject || updatingSubject}
      >
        <TableList
          data={subjects || []}
          disableSearch
          hideCSVDownload
          hidePagination
          hideHeader
          elevation={0}
          tableMetadata={{
            tableKey: 'subjectId',
            onClickCell: () => { },
            secondaryActions: [{
              key: '79c3c4b748a44733a7e791e0dfc9e6ee',
              callback: (subject) => {
                dispatch(openUpdateSubjectDialog());
                dispatch(setSubjectId(subject.subjectId));
              },
              icon: () => <EditTwoTone />,
              text: 'Update',
              disableCard: () => false,
            }, {
              key: '8b82b3a7b8bb469d9741e41b2231c9da',
              callback: (subject) => { archiveSubject({ subjectId: subject.subjectId }); },
              icon: () => <DeleteIcon />,
              text: 'Delete',
              disableCard: () => false,
            }],
            cells: [{
              key: 'name',
              header: 'Subject',
              value(data: SubjectType) { return `${data.name} (${data?.staff?.firstName} ${data?.staff?.lastName})`; },
              variant: 'name',
            }],
          }}
        />
      </CollapsibleCard>
      <UpdateSubjectDialog />
    </>
  );
}
