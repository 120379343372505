import React, { useEffect, useState } from 'react';
import {
  Grid, TextField, Typography,
} from '@mui/material';
import { closeUpdateEnrollmentSubjectDialog, KatangaSlice, useKatanga } from '@think-zambia-foundation/katanga-context';
import Autocomplete from '@mui/material/Autocomplete';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useCoreDialog, CoreDialogProvider } from '@think-zambia-foundation/context';
import { useSelector, useDispatch } from 'react-redux';

// eslint-disable-next-line react/prop-types
function Wrapped() {
  const [score, setUpdatedScore] = useState<number>();
  const {
    updateEnrollmentSubject,
    updateEnrollmentSubjectData,
    updatingEnrollmentSubject,
    errorUpdatingEnrollmentSubject,
  } = useKatanga();

  const dispatch = useDispatch();
  const updateEnrollmentSubjectId = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.updateEnrollmentSubjectId,
  );

  const updateEnrollmentSubjectDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.updateEnrollmentSubjectDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (score && updateEnrollmentSubjectId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [score]);

  useEffect(() => {
    setDialogOpen(updateEnrollmentSubjectDialogOpen);
  }, [updateEnrollmentSubjectDialogOpen]);

  useEffect(() => {
    if (updateEnrollmentSubjectData) {
      dispatch(closeUpdateEnrollmentSubjectDialog());
    }
  }, [updateEnrollmentSubjectData]);

  const handleSubmitUpdateEnrollmentSubject = () => {
    if (score && updateEnrollmentSubjectId) {
      updateEnrollmentSubject({
        enrollmentSubjectId: updateEnrollmentSubjectId,
        score,
      });
    }
  };

  return (
    <CoreDialog
      modalTitle="Update score"
      submitAction={() => handleSubmitUpdateEnrollmentSubject()}
      onClose={() => dispatch(closeUpdateEnrollmentSubjectDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={updatingEnrollmentSubject}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12}>
          {errorUpdatingEnrollmentSubject && (
            <Typography variant="body2" color="error">{errorUpdatingEnrollmentSubject.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <Autocomplete
            id="Score"
            fullWidth
            onChange={(event, value) => {
              if (value) {
                setUpdatedScore(parseInt(value, 10));
              }
            }}
            options={Array.from(Array(101).keys()).map((number) => `${number}`)}
            noOptionsText="between 0 and 100"
            style={{ width: 250 }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} fullWidth label="Enter Score" variant="outlined" />}
          />
        </Grid>
      </Grid>
    </CoreDialog>
  );
}

// eslint-disable-next-line react/prop-types
export function UpdateEnrollmentSubjectDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
