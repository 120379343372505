/* eslint-disable react/prop-types */
import {
  List, ListItemButton, ListItemText,
} from '@mui/material';
import { CollapsibleCard, SmartTypography } from '@think-zambia-foundation/components';
import { EnrollmentClassType } from '@think-zambia-foundation/katanga-context';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SelectClassProps } from './SelectClass.types';

export function SelectClass({ classes }: SelectClassProps) {
  const [searchParams] = useSearchParams();
  const selectedClassId = searchParams.get('selectedClassId');

  const navigate = useNavigate();

  function handleSelectClass(e: any, c: EnrollmentClassType) {
    if (!c.enrollmentClassId) return;
    e.preventDefault();
    navigate(`/?selectedClassId=${c.enrollmentClassId}`);
  }

  return (
    <CollapsibleCard
      title="Select Class"
    >
      {classes && (
        <List dense>
          {classes
            .map((c) => (
              <React.Fragment key={c.enrollmentClassId}>
                <ListItemButton
                  key={c.enrollmentClassName}
                  selected={selectedClassId === `${c.enrollmentClassId}`}
                  onClick={(e) => {
                    handleSelectClass(e, c);
                  }}
                >
                  <ListItemText>
                    <SmartTypography
                      text={c.enrollmentClassName.toLowerCase()}
                      caption={`${c.enrollmentGrade.school.name} | ${c.enrollmentGrade.enrollmentGradeType}`}
                      variant="name"
                    />
                  </ListItemText>
                </ListItemButton>
              </React.Fragment>
            ))}
        </List>
      )}
    </CollapsibleCard>
  );
}
