import React from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import { SmartTypography, CollapsibleCard, UpdateContactDialog } from '@think-zambia-foundation/components';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useDispatch } from 'react-redux';
import { openUpdateContactDialog, useCore } from '@think-zambia-foundation/context';
import { StudentContactInfoProps } from './StudentContactInfo.types';

export function StudentContactInfo({ student }: StudentContactInfoProps) {
  const dispatch = useDispatch();
  const { updatingContact } = useCore();

  if (!student?.contact) return <div />;
  return (
    <>
      <CollapsibleCard
        title="Student Contact Information"
        actions={[{
          key: '0764e3a4a3fb4e4087986182f0eab9d5',
          callback: () => dispatch(openUpdateContactDialog()),
          icon: <EditTwoToneIcon />,
        }]}
        loading={updatingContact}
      >
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SmartTypography
                caption="Address"
                text={student?.guardian?.address
                  ? `${student?.guardian?.address.addressLine1} ${student?.guardian?.address.addressLine2 ?? ''}`
                  : ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <SmartTypography
                caption="Phone number"
                variant="phone"
                text={student?.contact?.phone1 || ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={6} sm={4}>
              <SmartTypography
                caption="Secondary number"
                variant="phone"
                text={student?.contact?.phone2 || ''}
                gutterBottom
              />
            </Grid>
            <Grid item xs={12}>
              <SmartTypography
                caption="Email"
                text={student?.contact?.email || ''}
                gutterBottom
                variant="email"
              />
            </Grid>
          </Grid>
        </CardContent>
      </CollapsibleCard>
      <UpdateContactDialog contactId={student?.contact?.contactId} />
    </>
  );
}
