/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useCoreDialog, CoreDialogProvider,
  useCore,
} from '@think-zambia-foundation/context';
import { CoreDialog, SmartTypography } from '@think-zambia-foundation/components/';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import { GetFormattedPhoneNumberString } from '@think-zambia-foundation/utils';
import { useSelector, useDispatch } from 'react-redux';
import { closeMakePaymentOnlineDialog, KatangaSlice } from '@think-zambia-foundation/katanga-context';
import { MakePaymentDialogProps, PaymentOptionType } from './MakePaymentDialog.types';

function Wrapped({
  schoolId,
  studentId,
  referenceId,
  options,
  freeSolo,
}: MakePaymentDialogProps) {
  const [paymentAmount, setPaymentAmount] = useState<string>();
  const [customerName, setCustomerName] = useState<string>();
  const [customerEmail, setCustomerEmail] = useState<string>('');
  const [customerPhone, setCustomerPhone] = useState<string>('');
  const [note, setNote] = useState<string>();
  const [selectedOption, setSelectedOption] = useState<PaymentOptionType | undefined>();
  const dispatch = useDispatch();

  const makePaymentOnlineDialogOpen = useSelector(
    (state: { katanga:KatangaSlice }) => state.katanga.makePaymentOnlineDialogOpen,
  );

  const {
    dialogOpen,
    setDialogOpen,
    setSubmitDisabled,
    submitDisabled,
  } = useCoreDialog();

  const {
    addingPayment,
    addPayment,
    addPaymentData,
  } = useCore();

  const config = {
    public_key: `${process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY}`,
    tx_ref: `${Date.now()}`,
    amount: parseFloat(paymentAmount!) / 0.97,
    currency: 'ZMW',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: customerEmail,
      phone_number: customerPhone,
      name: `${customerName}`,
    },
    customizations: {
      title: 'Katanga',
      description: `Payment to ${schoolId} for ${studentId} : ${note}`,
      logo: '',
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    if (
      referenceId
      && note
      && paymentAmount
      && customerName
      && customerEmail
      && customerPhone
    ) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [
    paymentAmount,
    customerName,
    customerEmail,
    customerPhone,
  ]);

  useEffect(() => {
    if (addPaymentData) {
      dispatch(closeMakePaymentOnlineDialog());
    }
    // eslint-disable-next-line
  }, [addPaymentData]);

  useEffect(() => {
    setDialogOpen(makePaymentOnlineDialogOpen);
    // eslint-disable-next-line
  }, [makePaymentOnlineDialogOpen]);

  function onCustomerPhoneChange(event: any) {
    const phoneInputNumber = event.target.value.replace(/[^\d]/g, '').slice(3, 12);
    setCustomerPhone(phoneInputNumber);
  }

  return (
    <CoreDialog
      modalTitle="Make Payment"
      submitAction={() => handleFlutterPayment({
        callback: (response) => {
          if (paymentAmount) {
            addPayment({
              referenceId,
              note: note || '',
              paymentInvoice: `${response.transaction_id}`,
              paymentAmount: parseFloat(paymentAmount),
              paymentMadeBy: customerName || 'Flutterwave',
              paymentReceivedBy: 'Flutterwave',
            });
          }
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      })}
      onClose={() => dispatch(closeMakePaymentOnlineDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={addingPayment}
    >
      <FormControl>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => {
                if (typeof value !== 'string') {
                  setSelectedOption(value);
                  setNote(value.option);
                }
              }}
              onInputChange={(event, value) => {
                setNote(value);
              }}
              id="select-fee-autocomplete"
              freeSolo={freeSolo}
              options={options || [{ option: 'other' }]}
              getOptionLabel={(o) => {
                if (typeof o !== 'string') {
                  return o.option;
                }
                return o;
              }}
              noOptionsText="No fee options: contact admin"
              disableClearable
              value={selectedOption || undefined}
              fullWidth
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Payment for" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <SmartTypography
              caption="Total Due"
              text={selectedOption ? `${selectedOption.amount}` || 'amount not set' : 'select payment for'}
            />
          </Grid>
          <Grid item xs={12}>
            <SmartTypography
              caption="Processing Fee"
              text="3%"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              required
              fullWidth
              type="number"
              id="payment-amount-tf"
              onChange={(event) => setPaymentAmount(event.target.value)}
              variant="outlined"
              margin="normal"
              label="Payment Amount"
              name="Payment Amount"
              disabled={!freeSolo && !note}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Payment Made By"
              name="Payment Made By"
              id="payment-madeBy-tf"
              disabled={!freeSolo && !note}
              onChange={(event) => setCustomerName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              required
              fullWidth
              type="email"
              variant="outlined"
              margin="normal"
              label="Email"
              name="Email"
              id="customer-email-tf"
              disabled={!freeSolo && !note}
              onChange={(event) => setCustomerEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <TextField
              required
              variant="outlined"
              id="mobile"
              name="mobile"
              label="Mobile"
              margin="normal"
              autoComplete="mobile"
              onChange={(e) => onCustomerPhoneChange(e)}
              fullWidth
              value={customerPhone ? `${GetFormattedPhoneNumberString({ phone: customerPhone })}` : '+260 '}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function MakePaymentDialog({
  schoolId,
  studentId,
  referenceId,
  options,
  freeSolo,
}: MakePaymentDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped
        schoolId={schoolId}
        studentId={studentId}
        referenceId={referenceId}
        options={options}
        freeSolo={freeSolo}
      />
    </CoreDialogProvider>
  );
}
