import { Grid } from '@mui/material';
import { useKatanga } from '@think-zambia-foundation/katanga-context';
import React from 'react';
import { CollapsibleCard } from '@think-zambia-foundation/components';
import { useAuth } from '@think-zambia-foundation/context';
import { MyStudents } from '../MyStudents';
import { CapacityReportSummary } from '../CapacityReport';
import { TeacherDashboard } from '../TeacherDashboard';

/**
 * Katanga welcome dashboard
 * @returns
 */
export function Dashboard(){
  const {
    fetchCapacityReportData,
    fetchingTeacherById,
    fetchingStudentsByUid,
    fetchingCapacityReport,
  } = useKatanga();

  const {
    hasPermissionToClaim,
  } = useAuth();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={4}
    >
      {hasPermissionToClaim('katangaReportAdmin') && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="Capacity Report Summary"
            elevation={0}
            loading={fetchingCapacityReport}
          >
            <CapacityReportSummary capacityReportData={fetchCapacityReportData?.capacityReport} />
          </CollapsibleCard>
        </Grid>
      )}
      {hasPermissionToClaim('katangaStudent') && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="My Students"
            elevation={0}
            loading={fetchingStudentsByUid}
          >
            <MyStudents />
          </CollapsibleCard>
        </Grid>
      )}
      {hasPermissionToClaim('katangaTeacher') && (
        <Grid item xs={12}>
          <CollapsibleCard
            title="Teacher Dashboard"
            elevation={0}
            loading={fetchingTeacherById}
          >
            <TeacherDashboard />
          </CollapsibleCard>
        </Grid>
      )}
    </Grid>
  );
}
