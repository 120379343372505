/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { FormControl, Grid, TextField } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import { useKatanga, KatangaSlice, closeUpdateFeeDialog } from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { UpdateFeesDialogProps } from './UpdateFeesDialog.types';

const feeTypes = [
  'Library Charge',
  'Other',
  'PTA',
  'Pupil Identity Card',
];

function Wrapped({
  feeId,
}: UpdateFeesDialogProps) {
  const [selectedFeeType, setSelectedFeeType] = useState<string>('');
  const [feeDescription, setFeeDescription] = useState<string>('');
  const [feeAmount, setFeeAmount] = useState<number>();
  const dispatch = useDispatch();
  const updateFeeDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.updateFeeDialogOpen,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (feeId) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    // eslint-disable-next-line
  }, [feeId]);

  useEffect(() => {
    setSelectedFeeType('');
    setFeeDescription('');
    setFeeAmount(0);
    setDialogOpen(updateFeeDialogOpen);
  }, [updateFeeDialogOpen]);

  const {
    updatingFee,
    updateFeeData,
    updateFee,
  } = useKatanga();

  useEffect(() => {
    if (updateFeeData) {
      dispatch(closeUpdateFeeDialog());
    }
  }, [updateFeeData]);

  function handleSubmitUpdateFees() {
    updateFee({
      feeId,
      amount: feeAmount,
      feeType: selectedFeeType,
      description: feeDescription,
    });
  }

  return (
    <CoreDialog
      modalTitle="Update Fees"
      submitAction={() => handleSubmitUpdateFees()}
      onClose={() => dispatch(closeUpdateFeeDialog())}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={updatingFee}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Autocomplete
              onChange={(event, value) => setSelectedFeeType(value)}
              id="select-fee-autocomplete"
              options={feeTypes}
              disableClearable
              value={selectedFeeType || undefined}
              fullWidth
              renderInput={(params) => <TextField {...params} label="Select Fee Type" variant="outlined" />}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              variant="outlined"
              margin="normal"
              label="Fee Description"
              name="Fee Description"
              id="Fee-description-tf"
              onChange={(event) => setFeeDescription(event.target.value)}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type="number"
              id="fee-amount-tf"
              onChange={(event) => setFeeAmount(parseFloat(event.target.value))}
              variant="outlined"
              margin="normal"
              label="Fee Amount"
              name="Fee Amount"
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdateFeesDialog({
  feeId,
}: UpdateFeesDialogProps) {
  return (
    <CoreDialogProvider>
      <Wrapped
        feeId={feeId}
      />
    </CoreDialogProvider>
  );
}
