/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  FormControl, Grid, TextField, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  closeUpdateSubjectDialog,
  KatangaSlice,
  setSubjectId,
  useKatanga,
} from '@think-zambia-foundation/katanga-context';
import { CoreDialogProvider, useCoreDialog, useCore } from '@think-zambia-foundation/context';
import { CoreDialog } from '@think-zambia-foundation/components';
import { useSelector, useDispatch } from 'react-redux';

function Wrapped() {
  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [subjectName, setSubjectName] = useState<string>();
  const [selectedStaffId, setSelectedStaffId] = useState<string>();

  const {
    updatingSubject,
    updateSubject,
    updateSubjectData,
  } = useKatanga();

  const updateSubjectDialogOpen = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.updateSubjectDialogOpen,
  );
  const subjectId = useSelector(
    (state: { katanga: KatangaSlice }) => state.katanga.subjectId,
  );

  const {
    dialogOpen,
    submitDisabled,
    setDialogOpen,
    setSubmitDisabled,
  } = useCoreDialog();

  useEffect(() => {
    if (subjectId) {
      setSubmitDisabled(false);
    }
  }, [subjectId]);

  useEffect(() => {
    setDialogOpen(updateSubjectDialogOpen);
  }, [updateSubjectDialogOpen]);

  const handleSubmitUpdateSubject = () => {
    updateSubject({
      subjectId,
      name: subjectName,
      staffId: selectedStaffId,
    }).then(() => {
      dispatch(setSubjectId(''));
    });
  };

  const {
    errorFetchingStaffList,
    getStaff,
    fetchStaffListData,
  } = useCore();

  useEffect(() => {
    getStaff();
  }, []);

  useEffect(() => {
    if (updateSubjectData) {
      dispatch(closeUpdateSubjectDialog());
    }
  }, [updateSubjectData]);

  useEffect(() => {
    if (errorFetchingStaffList) {
      setErrorMessage('error fetching staff list');
    }
  }, [errorFetchingStaffList]);

  return (
    <CoreDialog
      modalTitle="Update Subject"
      submitAction={() => handleSubmitUpdateSubject()}
      onClose={() => {
        dispatch(closeUpdateSubjectDialog());
      }}
      dialogOpen={dialogOpen}
      submitDisabled={submitDisabled}
      loading={updatingSubject}
    >
      <FormControl>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            {errorMessage && (
            <Typography variant="body2" color="error">
              {errorMessage}
            </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              label="Subject Name"
              id="subject name"
              onChange={(event) => setSubjectName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              onChange={(event, value) => setSelectedStaffId(value?.staffId)}
              id="select-staff-autocomplete"
              options={fetchStaffListData
                    && fetchStaffListData.staffList ? fetchStaffListData.staffList : []}
              getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
              noOptionsText="No staff options: contact admin"
              renderInput={(params) => <TextField {...params} label="Select Teacher" variant="outlined" />}
            />
          </Grid>
        </Grid>
      </FormControl>
    </CoreDialog>
  );
}

export function UpdateSubjectDialog() {
  return (
    <CoreDialogProvider>
      <Wrapped />
    </CoreDialogProvider>
  );
}
