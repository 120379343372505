import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Grid, Hidden } from '@mui/material';
import { useAuth } from '@think-zambia-foundation/context';
import { useNavigate } from 'react-router-dom';
import { Footer } from '@think-zambia-foundation/components';

const PREFIX = 'Login';

const classes = {
  login: `${PREFIX}-login`,
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  loginHero: `${PREFIX}-loginHero`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  },
) => ({
  [`& .${classes.login}`]: {
    height: '100vh',
  },

  [`& .${classes.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(3),
  },

  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.form}`]: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(2, 0, 2),
    padding: theme.spacing(2),
  },

  [`& .${classes.loginHero}`]: {
    background: theme.palette.primary.light,
    height: '100vh',
  },
}));

export function Login() {
  const [error, setError] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const {
    login,
    currentUser,
    loadingCurrentUser,
    hasPermissionToClaim,
  } = useAuth();

  const navigate = useNavigate();

  function handleSubmit(event: any) {
    event.preventDefault();
    const appEnvironmentId = '';
    setError('');
    if (email && password) {
      login(email, password, appEnvironmentId).then(() => {
      }).catch(() => {
        setError('Login failed! Please enter valid username & password');
      });
    }
  }

  useEffect(() => {
    if (currentUser) {
      if (hasPermissionToClaim('katangaStudent')) {
        navigate('/mystudents');
      } else {
        navigate('/');
      }
    }
  }, [currentUser]);

  return (
    (
      <Root>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          className={classes.login}
        >
          <Hidden mdDown>
            <Grid item xs={6} className={classes.loginHero}>
              <Container component="main">
                <CssBaseline />
                <div className={classes.paper}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    className={classes.login}
                  >
                    <Grid item>
                      <Typography
                        variant="h1"
                        align="left"
                        gutterBottom
                      >
                        Katanga
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        gutterBottom
                        color="textSecondary"
                      >
                        School
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        gutterBottom
                        color="textSecondary"
                      >
                        Administration
                      </Typography>
                      <Typography
                        variant="h4"
                        align="left"
                        gutterBottom
                        color="textSecondary"
                      >
                        Software
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </Grid>
          </Hidden>
          <Grid
            item
            sm={6}
            alignContent="center"
          >
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
                <form className={classes.form} noValidate>
                  <TextField
                    type="email"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    placeholder="Email"
                    autoComplete="email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={(e) => handleSubmit(e)}
                    disabled={loadingCurrentUser}
                  >
                    Sign In
                  </Button>
                  <Footer columns={[]} />
                </form>
              </div>
            </Container>
          </Grid>
        </Grid>
      </Root>
    )
  );
}
