import { StaffList } from '@think-zambia-foundation/components';
import {
  ApplicationDetails,
  InvoiceDetails,
  KatangaStaffDetails,
  MyStudents,
  PaymentReport,
  StudentDetails,
  StudentList,
  StudentRegistration,
  TeacherDashboard,
  CapacityReport,
  ApplicationList,
  Dashboard,
  EnrollmentReport,
  SchoolDetails,
  SchoolList,
  BulkOperations,
} from './pages';

export const routes = [
  {
    exact: true,
    path: '/',
    component: Dashboard,
    title: 'Welcome',
  },
  {
    exact: true,
    path: '/student',
    component: StudentList,
    title: 'Students',
  },
  {
    exact: true,
    path: '/mystudents',
    component: MyStudents,
    title: 'My Students',
  },
  {
    exact: true,
    path: '/student/registration',
    component: StudentRegistration,
    title: 'Student Registration',
  },
  {
    exact: false,
    path: '/student/:studentId',
    component: StudentDetails,
    title: 'Student Details',
  },
  {
    exact: true,
    path: '/report/payment',
    component: PaymentReport,
    title: 'Payment Report',
    enabled: process.env.REACT_APP_PAYMENT_REPORT,
  },
  {
    exact: true,
    path: '/report/enrollment',
    component: EnrollmentReport,
    title: 'Enrollment Report',
    enabled: process.env.REACT_APP_ENROLLMENT_REPORT,
  }, {
    exact: true,
    path: '/report/capacity',
    component: CapacityReport,
    title: 'Capacity Report',
    enabled: process.env.REACT_APP_ENROLLMENT_REPORT,
  },
  {
    exact: true,
    path: '/staff',
    component: StaffList,
    title: 'Staff',
  },
  {
    exact: true,
    path: '/staff/:staffId',
    component: KatangaStaffDetails,
    title: 'Staff Details',
  },
  {
    exact: true,
    path: '/settings',
    component: SchoolList,
    title: 'Schools',
  },
  {
    exact: true,
    path: '/school/:schoolId',
    component: SchoolDetails,
    title: 'Schools Details',
  },
  {
    exact: true,
    path: '/application',
    component: ApplicationList,
    title: 'Applications',
  },
  {
    exact: true,
    path: '/application/:applicationId',
    component: ApplicationDetails,
    title: 'Application Details',
  },
  {
    exact: true,
    path: '/dashboard/teacher',
    component: TeacherDashboard,
    title: 'Application Details',
  },
  {
    exact: true,
    path: '/bulk-operations',
    component: BulkOperations,
    title: 'Bulk Operations',
  },
];

export const publicRoutes = [
  {
    exact: true,
    path: '/invoice/:studentId',
    component: InvoiceDetails,
    title: 'Invoice Details',
  },
];
